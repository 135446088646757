import { StatsDataInterface, StatsGroupedByDate } from '@/types/stats';
import { IEspAccount } from '@/types/esp-account';

export function sortStatsData(
  statsData: StatsDataInterface[],
  accounts: IEspAccount[],
): StatsGroupedByDate {
  const sortedStatsData: StatsGroupedByDate = {};

  const dates = statsData.map((record) => {
    return record.date;
  });
  const uniqueDates = dates.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  uniqueDates.forEach((date) => {
    if (!sortedStatsData[date]) sortedStatsData[date] = {};
    accounts.forEach((acc) => {
      sortedStatsData[date][acc.name] = {
        sent: 0,
        date: date,
        accountName: acc.name,
        delivered: 0,
        opens: 0,
        clicks: 0,
        unsubs: 0,
        offer: 0,
        adsense: 0,
        revenue: 0,
        OR: '',
        CR: '',
        UR: '',
        rpc: 0,
        ecpm: 0,
      } as StatsDataInterface;
    });
  });
  statsData.forEach((record) => {
    if (!sortedStatsData[record.date]) sortedStatsData[record.date] = {};
    sortedStatsData[record.date][record.accountName || 'all'] = {
      sent: record.sent,
      date: record.date,
      accountName: record.accountName,
      delivered: record.ecpm,
      opens: record.opens,
      clicks: record.clicks,
      unsubs: record.unsubs,
      offer: record.offer,
      adsense: record.adsense,
      revenue: record.revenue,
      OR: record.OR,
      CR: record.CR,
      UR: record.UR,
      rpc: record.rpc,
      ecpm: record.ecpm,
    };
  });

  return sortedStatsData;
}
