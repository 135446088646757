import React from 'react';
import { Outlet } from 'react-router-dom';
import 'react-modern-drawer/dist/index.css';
import UnderReviewHeader from '@/components/under-review-header/UnderReviewHeader';

const UnderReviewLayout = () => {
  return (
    <>
      <UnderReviewHeader />
      <Outlet />
    </>
  );
};

export default UnderReviewLayout;
