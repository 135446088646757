import {InternalAxiosRequestConfig} from 'axios';
import {err} from 'neverthrow';

import {authApiUrls} from '@/urls/auth-api-urls';
import {
  getAccessTokenData, getAccessToken, getRefreshToken
} from '@/store/auth/auth-store';
import {isExpired} from '@/utils/token';
import {fetchRefreshToken} from "@/utils/auth/requests/refresh-token-request";

const refreshTokenIfExpired = async (exp: number | undefined) => {
  if (isExpired(exp)) {
    return await fetchRefreshToken();
  } else {
    return err('token is not expired');
  }
};
export const requestHeadersInterceptorOnFulfilled = async (
  config: InternalAxiosRequestConfig<any>
) => {
  console.log('request URL', config.url);
  const protectedUrls = [
    authApiUrls.users.me,
    authApiUrls.users.tokens,
    authApiUrls.users.signin,
    authApiUrls.users.signup,
    authApiUrls.users.recoverPassword,
  ];

  if (!protectedUrls.includes(config.url || '')) {
    const tokenData = getAccessTokenData();
    const result = await refreshTokenIfExpired(tokenData?.exp);
    console.log('refreshresult', result);
  }
  const token = getAccessToken();
  const refreshToken = getRefreshToken();
  if (token && refreshToken) {
    config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['Refresh-Token'] = refreshToken;
  }
  return config;
};
