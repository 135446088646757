import axios from 'axios';
import {
 err, ok, Result 
} from 'neverthrow';

import { useAuthStore } from '@/store/auth/auth-store';

import { authApiUrls } from '@/urls/auth-api-urls';

const apiClientInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 1000,
});

export const fetchRefreshToken = async (): Promise<Result<string, string>> => {
  const { refreshToken, accessToken, setAccessToken, setRefreshToken } =
    useAuthStore.getState();
  try {
    const rs = await apiClientInstance.post(
      authApiUrls.users.tokens,
      {},
      {
        headers: {
          Authorization: 'Bearer' + accessToken,
          'Refresh-Token': refreshToken,
        },
      },
    );
    if (rs.status === 200) {
      const accessToken = rs.headers['Access-Token'];
      const refreshToken = rs.headers['Refresh-Token'];
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      return ok('success');
    } else {
      setAccessToken(null);
      setRefreshToken(null);
      return err('failed');
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log('fetch token error:', error);
      return err(error.message);
    } else {
      console.log('fetch token error');
      return err('fetch token error');
    }
  }
};
