import {
 devtools, createJSONStorage, persist 
} from 'zustand/middleware';
import { create, useStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { decodeAccessToken, TokenData } from '@/utils/token';
import { User } from '@/schemas/user';

type AuthStore = {
  accessToken: string | null;
  refreshToken: string | null;
  accessTokenData: TokenData | null;
  currentUser: User | null;
  approvalStatus: boolean | null;

  setCurrentUser: (user: User | null) => void;
  setAccessToken: (accessToken: string | null) => void;
  setRefreshToken: (refreshToken: string | null) => void;
  init: () => void;
  clearTokens: () => void;
};

export const useAuthStore = create<
  AuthStore,
  [
    ['zustand/devtools', never],
    ['zustand/persist', never],
    ['zustand/immer', never],
  ]
>(
  devtools(
    persist(
      immer<AuthStore>((set, get) => ({
        accessToken: null,
        accessTokenData: null,
        refreshToken: null,
        currentUser: null,
        approvalStatus: null,

        setCurrentUser: (user: User | null) => {
          const currentUser = user;
          set({ currentUser });
        },
        setAccessToken: (accessToken: string | null) => {
          const accessTokenData = (() => {
            try {
              return accessToken ? decodeAccessToken(accessToken) : undefined;
            } catch (error) {
              console.error(error);
              return undefined;
            }
          })();
          set({
            accessToken,
            accessTokenData,
          });
        },
        setRefreshToken: (refreshToken: string | null) => set({ refreshToken }),
        init: () => {
          const { setAccessToken, setRefreshToken } = get();
          setAccessToken('');
          setRefreshToken('');
        },
        clearTokens: () =>
          set({
            accessToken: null,
            refreshToken: null,
          }),
      })),
      {
        name: 'auth-storage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('AuthStore', useAuthStore);
}
export type ExtractState<S> = S extends {
  getState: () => infer T;
}
  ? T
  : never;

type Params<U> = Parameters<typeof useStore<typeof useAuthStore, U>>;
// Selectors
const accessTokenSelector = (state: ExtractState<typeof useAuthStore>) =>
  state.accessToken;
const accessTokenDataSelector = (state: ExtractState<typeof useAuthStore>) =>
  state.accessTokenData;
const refreshTokenSelector = (state: ExtractState<typeof useAuthStore>) =>
  state.refreshToken;
const currentUserSelector = (state: ExtractState<typeof useAuthStore>) =>
  state.currentUser;
//
// // getters
export const getAccessTokenData = () =>
  accessTokenDataSelector(useAuthStore.getState());
export const getAccessToken = () =>
  accessTokenSelector(useAuthStore.getState());
export const getRefreshToken = () =>
  refreshTokenSelector(useAuthStore.getState());
//
// function useAuthStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
//   return useStore(authStore, selector, equalityFn);
// }
//
// // Hooks
// export const useAccessToken = () => useAuthStore(accessTokenSelector);
// export const useRefreshToken = () => useAuthStore(refreshTokenSelector);
// export const useCurrentUser = () => useAuthStore(currentUserSelector);
// export const useActions = () => useAuthStore(actionsSelector);
