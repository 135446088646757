import React, { useEffect } from 'react';
import DashboardHeadingPanel from '@/components/Dashboard/DashboardHeadingPanel';
import SendsView from '@/components/Dashboard/SendsView';
import RampupView from '@/components/Dashboard/RampupView';
import { useStatsStore } from '@/store/stats-store';
import { useFlashStore } from '@/store/flash-store';
import { useDashboardViewStore } from '@/store/dashboard-view-store';
import ToggleSettingsButton from '@/components/ToggleSettingsButton';

const DashboardPage: React.FC = () => {
  const {
    error,
  } = useStatsStore();
  const { addMessage } = useFlashStore();
  const {currentView} = useDashboardViewStore();

  useEffect(() => {
    if (error) {
      addMessage('error', error);
    }
  }, [error, addMessage]);

  return (
    <div className="container mx-auto w-full px-4">
      <h1 className="py-2 text-lg">
        { currentView } Dashboard
        { currentView === 'Rampup' && <ToggleSettingsButton /> }
      </h1>
      
      <DashboardHeadingPanel mode="esp" />
      { currentView === 'Sends' && <SendsView/> }
      { currentView === 'Rampup' && <RampupView/> }
    </div>
  );
};

export default DashboardPage;
