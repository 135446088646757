import React, { useState, ReactNode } from 'react';

interface AccordionItemProps {
  title: string;
  content: string | ReactNode;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-2 border-gray-100 ">
      <button onClick={toggleOpen} className="flex items-center justify-between w-full py-2 px-4 focus:outline-none">
        <h1 className="text-xs font-semibold text-gray-500">{title}</h1>
        <span className={isOpen ? "text-gray-400 bg-gray-200 rounded-full" : "text-white bg-blue-500 rounded-full" }>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isOpen ? "M18 12H6" : "M12 6v6m0 0v6m0-6h6m-6 0H6"}></path>
          </svg>
        </span>
      </button>
      <hr className="border-gray-200 " />
      {isOpen && (
        <div className="w-full">
          {content}
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
