import axios, { AxiosHeaders } from 'axios';
import { ResultAsync } from 'neverthrow';

import { requestHeadersInterceptorOnFulfilled } from './request-headers-interceptor';
import { responseExpiredTokenInterceptorReject } from './response-expired-token-interceptor';

export const apiClientInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 1000,
});

apiClientInstance.interceptors.request.use(
  requestHeadersInterceptorOnFulfilled,
  (error) => Promise.reject(error),
);
apiClientInstance.interceptors.response.use(
  (response) => response,
  responseExpiredTokenInterceptorReject,
);

export const hasHeaders = (
  responseHeaders: AxiosHeaders,
  headers: string[],
) => {
  return headers.map((header) => Object.keys(responseHeaders).includes(header));
};

export const authPostJsonClient = async (
  url: string,
  fields: {
    email: string;
    password?: string;
    password_confirmation?: string;
  },
) => {
  return ResultAsync.fromPromise(
    apiClientInstance.post(`${url}`, fields),
    (error) => {
      return error as Error;
    },
  );
};
