import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type ListIdState = {
  listIds: string[];
  selectedListIds: string[];
  error: string | null;
};
type ListIdActions = {
  setListIds: (listIds: string[]) => void;
  setSelectedListIds: (listIds: string[]) => void;
  addToListIds: (listId: string) => void;
  removeFromListIds: (listId: string) => void;
};

export const useListIdStore = create<
  ListIdState & ListIdActions,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer<ListIdState & ListIdActions>((set) => ({
      listIds: [],
      selectedListIds: [],
      error: null,
      setListIds: (listIds: string[]) => {
        set((state) => {
          state.listIds = listIds;
        });
      },
      setSelectedListIds: (listIds: string[]) => {
        set((state) => {
          state.selectedListIds = listIds;
        });
      },
      addToListIds: (listId: string) => {
        set((state) => {
          if (!state.selectedListIds.includes(listId)) {
            state.selectedListIds.push(listId);
          }
        });
      },
      removeFromListIds: (listId: string) => {
        set((state) => {
          state.selectedListIds = state.selectedListIds.filter(
            (id) => id !== listId,
          );
        });
      },
    })),
  ),
);
