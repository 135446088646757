import React, { useState, useEffect, useRef } from 'react';
import { Vega } from 'react-vega';
import { TopLevelSpec as VlSpec } from 'vega-lite';

import './base_chart.css'

interface BaseChartProps {
  spec: VlSpec;
  twclasses?: string;
  actions?: boolean;
}

const BaseChart: React.FC<BaseChartProps> = ({ spec, twclasses = '', actions = true }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(900);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth - 100
        setWidth(width);
      }
    };

    handleResize(); // Initial setting
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updatedSpec = {
    ...spec,
    width,
  };

  return (
    <div ref={containerRef} className={ "max-w-[85%] mx-auto " + twclasses }>
      <Vega spec={updatedSpec} actions={actions} />
    </div>
  );
};

export default BaseChart;
