import React from 'react';
import { Message } from '@/types/flash';
import { useFlashStore } from '@/store/flash-store';

const FlashMessage: React.FC = () => {
  const messages = useFlashStore(state => state.messages);
  const removeMessage = useFlashStore(state => state.removeMessage);

  const messageColor = (type: Message['type']) => {
    switch (type) {
      case 'success':
        return 'bg-green-500';
      case 'error':
        return 'bg-red-500';
      default:
        return 'bg-blue-500';
    }
  }
  return (
    <div className="fixed bottom-0 right-0 mb-4 mr-4 space-y-2 z-50">
      {messages.map((message) => (
        <div key={message.id} className={`p-4 rounded shadow-lg text-white ${messageColor(message.type)}`} onClick={() => removeMessage(message.id)}>
          <p className="font-bold">{message.type.toUpperCase()}</p>
          <p>{message.content}</p>
        </div>
      ))}
    </div>
  );
}

export default FlashMessage;
