import React, { useEffect } from 'react';

import { useEspAccountsStore } from '@/store/esp-accounts-store';
import { IEspAccount } from '@/types/esp-account';

import {
 Button, Menu, MenuItem, Popover 
} from '@blueprintjs/core';

import {
 ItemPredicate, ItemRenderer, Select 
} from '@blueprintjs/select';

const filterAccount: ItemPredicate<IEspAccount> = (
  query,
  espAccount,
  _index,
  exactMatch,
) => {
  const normalizedName = espAccount.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  } else {
    return (
      `${espAccount.name}. ${normalizedName}`.indexOf(normalizedQuery) >= 0
    );
  }
};
const renderAccount: ItemRenderer<IEspAccount> = (
  espAccount,
  { handleClick, handleFocus, modifiers, query },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      key={espAccount.id}
      text={espAccount.name}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
    />
  );
};
export default function EspAccountDropdown() {
  const {
    espAccounts,
    fetchEspAccounts,
    setCurrentEspAccount,
    currentEspAccount,
  } = useEspAccountsStore();

  useEffect(() => {
    fetchEspAccounts();
  }, [fetchEspAccounts]);

  return (
    <>
      <Select<IEspAccount>
        fill={true}
        items={espAccounts}
        itemPredicate={filterAccount}
        itemRenderer={renderAccount}
        popoverProps={{
          minimal: true,
        }}
        noResults={
          <MenuItem
            disabled={true}
            text="No results."
            roleStructure="listoption"
          />
        }
        onItemSelect={(esp) => {
          if (esp == currentEspAccount) {
            setCurrentEspAccount(null);
          } else {
            setCurrentEspAccount(esp);
          }
        }}
      >
        <Button
          text={currentEspAccount?.name ?? 'Select Account'}
          rightIcon="double-caret-vertical"
          placeholder="Select Account"
        />
      </Select>
    </>
  );
}
