import React from 'react';
import { useDashboardViewStore } from '@/store/dashboard-view-store';

const RampupSwitch = () => {
  const {currentRampupView, switchRampupView} = useDashboardViewStore(); // Initially set to 'Overview'

  return (
    <div className="flex justify-end items-end mb-px">
        <div className="text-gray-400 border-transparent inline-flex items-center h-[30px] -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap">
          Select View:
        </div>
        <button 
          onClick={() => switchRampupView('Overview')} 
          className={`${currentRampupView === 'Overview' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'} inline-flex items-center h-[30px] px-4 -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap cursor-pointer focus:outline-none`}>
            Overview
        </button>

        <button
          onClick={() => switchRampupView('Account')} 
          className={`${currentRampupView === 'Account' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'} inline-flex items-center h-[30px] px-4 -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap cursor-pointer focus:outline-none`}>
            Account
        </button>

        <button 
          onClick={() => switchRampupView('Date')}
          className={`${currentRampupView === 'Date' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'} inline-flex items-center h-[30px] px-4 -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap cursor-pointer focus:outline-none`}>
            Date
        </button>
    </div>
  )
}

export default RampupSwitch;
