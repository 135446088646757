import React, { FC, ChangeEvent } from 'react';
import { Label } from '@blueprintjs/core';

interface Props {
  name: string;
  value: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ThresholdInput: FC<Props> = ({ name, value, onChange }) => {
  return (
    <div className="w-full max-w-xs">
      <Label className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700">
        {name}
        <input
          className="bp5-input"
          id={name}
          type="threshold"
          value={value}
          onChange={onChange}
        />
      </Label>
    </div>
  );
};

export default ThresholdInput;
