import React from 'react';
import BaseChart from './BaseChart';
import DoubleAxisTooltip from './DoubleAxisTooltip';
import { YField } from './DoubleAxisTooltip';
import { TopLevelSpec as VlSpec } from 'vega-lite';
import { StatsDataInterface } from '@/types/stats';

interface ClicksAndSendsChartProps {
  data: StatsDataInterface[];
}

const ClicksAndSendsChart: React.FC<ClicksAndSendsChartProps> = ({ data }) => {
  const yFields: YField[] = [
    { field: "totalSentSum", type: "quantitative", title: "Sent", format: ".3s", as: "average_sent" },
    { field: "totalClicksSum", type: "quantitative", title: "Clicks", format: ".3s", as: "average_clicks" }
  ];
  const spec: VlSpec = {
    "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
    "data": { "values": data },
    "transform": [
      {
        "calculate": "datetime(datum.date)",
        "as": "date"
      },
      {
        "aggregate": yFields.map(field => ({ "op": "mean", "field": field.field, "as": field.as})),
        "groupby": ["date"]
      }
    ],
    "resolve": {
      "scale": {
        "y": "independent"
      }
    },
    "layer": [
      {
        "mark": {
          "type": "line",
          "interpolate": "monotone"
        },
        "encoding": {
          "x": {
            "field": "date",
            "type": "temporal",
            "axis": {
              "format": "%m/%d/%Y",
              "title": "Date",
              "titleFontSize": 0,
            }
          },
          "y": {
            "field": "average_sent",
            "type": "quantitative",
            "axis": {
              "title": "Sent",
              "titleColor": "#f58419",
              "titleFontSize": 8,
              "format": ".3s",
            }
          },
          "color": {
            "value": "#f58419"
          },
          "tooltip": [
            { "field": "date", "type": "temporal", "title": "Date" },
            { "field": "average_clicks", "type": "quantitative", "title": "Clicks", "format": ".3s" },
            { "field": "average_sent", "type": "quantitative", "title": "Sent", "format": ".3s" },
          ]
        }
      },
      {
        "mark": {
          "type": "line",
          "interpolate": "monotone"
        },
        "encoding": {
          "x": {
            "field": "date",
            "type": "temporal"
          },
          "y": {
            "field": "average_clicks",
            "type": "quantitative",
            "axis": {
              "title": "Clicks",
              "titleColor": "#4c78a8",
              "titleFontSize": 8,
              "format": ".3s",
            }
          },
          "color": {
            "value": "#4c78a8"
          },
          "tooltip": [
            { "field": "date", "type": "temporal", "title": "Date" },
            { "field": "average_clicks", "type": "quantitative", "title": "Clicks", "format": ".3s" },
            { "field": "average_sent", "type": "quantitative", "title": "Sent", "format": ".3s" },
          ]
        }
      }
    ]
  };

  return (
    <>
      <BaseChart spec={spec} />
      <DoubleAxisTooltip
        data={data}
        yFields={yFields}
        twclasses="mt-[-235px]"
      />
    </>
  );
};

export default ClicksAndSendsChart;

