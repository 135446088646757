import React from 'react';
import { FilledButton } from '@/components/shared/buttons/FilledButton';
import { BorderedButton } from '@/components/shared/buttons/BorderedButton';
import { Link } from 'react-router-dom';

export default function LoggedOutHeader() {
  return (
    <div
      className="header flex flex-wrap items-center justify-between
                    bg-white px-6 py-4 shadow"
    >
      <div className="flex items-center">LGD</div>
      <div className="flex gap-2">
        <Link to="/login">
          <BorderedButton
            additionalClasses=""
            content={<span className="text-black">Login</span>}
          />
        </Link>
        <Link to="/sign-up">
          <FilledButton
            additionalClasses=""
            content={<span className="text-white">Create your account</span>}
          />
        </Link>
      </div>
    </div>
  );
}
