import React from 'react';
import AccordionItem from '../AccordionItem';
import RampupDateTable from './RampupDateTable'; // Assuming RampupDateTable is in the same directory
import { RampupDataInterface } from '@/types/rampup';
import { useListIdStore } from '@/store/esp-lists-store';


import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';

interface DataTableProps {
  data: RampupDataInterface[];
}



const DateTables: React.FC<DataTableProps> = ({ data }) => {
  const {
    selectedListIds,
  } = useListIdStore();
  console.log('selectedListIds', selectedListIds)
  // Extract unique dates from the data
  const uniqueDates = Array.from(new Set(data.map(d => d.date)));
  let filteredData = data
  if (selectedListIds.length > 0) {
    filteredData = data.filter(d => selectedListIds.includes(d.listId.toString()))
  }

  const renderMultipleDates = (uniqDates: string[]) => {
    return uniqDates.map(date => {
      const dateData = filteredData.filter(d => d.date === date)
      const dateComponent = dateData.length === 0 ? (<></>) : (
        <AccordionItem
        key={date}
        title={date}
        content={<RampupDateTable data={dateData} />}
      />
      )
      return dateComponent
    })
  }
  return (
    <div className="space-y-1">
      {uniqueDates.length === 1 ? (<RampupDateTable data={filteredData} />) : renderMultipleDates(uniqueDates)}
    </div>
  );
};

export default DateTables;
