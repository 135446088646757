import React from 'react';
import {
 useForm, SubmitHandler, Controller 
} from 'react-hook-form';
import { Checkbox } from '@blueprintjs/core';
import TextHookFormInput from '@/components/shared/inputs/TextHookFormInput';
import { FilledButton } from '@/components/shared/buttons/FilledButton';
import { Link, useNavigate } from 'react-router-dom';
import { signIn } from '@/utils/auth/requests/sign-in-request';
import { useAuthStore } from '@/store/auth/auth-store';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const validationSchema = z.object({
  email: z.string().min(1, { message: 'Required' }),
  password: z.string().min(1, { message: 'Required' }),
  remember: z.boolean().optional(),
});

export type SignInValidationSchema = z.infer<typeof validationSchema>;

export function LoginForm() {
  const navigate = useNavigate();
  const { setRefreshToken, setAccessToken } = useAuthStore();
  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInValidationSchema>({resolver: zodResolver(validationSchema),});
  const onSubmit: SubmitHandler<SignInValidationSchema> = async (data) => {
    const signInResult = await signIn(data);
    signInResult.match(
      ({ accessToken, refreshToken }) => {
        setAccessToken(accessToken as string);
        setRefreshToken(refreshToken as string);
        navigate('/');
      },
      (error) => {
        setError('root.serverError', { type: '422', message: error.message });
        console.log('LoginForm - onSubmit error: ', error);
      },
    );
  };
  const invalidCredsError = errors?.root?.serverError?.type === '422';
  return (
    <form
      onSubmit={handleSubmit(
        (data) => {
          console.log('errors', errors);
          onSubmit(data);
        },
        (errors) => {
          console.log('Login - errors', errors);
        },
      )}
      className="flex flex-col gap-[20px]"
    >
      <TextHookFormInput
        error={invalidCredsError || errors.email || false}
        type="email"
        placeholder="Email"
        register={register('email', { required: true })}
      />

      <TextHookFormInput
        error={invalidCredsError || errors.password || false}
        placeholder="Password"
        type="password"
        register={register('password', { required: true })}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Checkbox
            className="text-black"
            checked={value}
            label="Remember Me"
            onChange={onChange}
          />
        )}
        name="remember"
        defaultValue={false}
      />

      <div className="flex justify-end">
        <Link
          to="/forgot-password"
          className="font-['Avenir'] text-base font-normal text-zinc-800"
        >
          Reset password
        </Link>
      </div>
      <FilledButton
        additionalClasses="w-full"
        content={<span className="text-white">Submit</span>}
      />
    </form>
  );
}
