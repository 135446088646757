import React from 'react';

import FlashMessage from '@/components/FlashMessage';
import Navbar from '@/components/Navbar';
import { useDrawerStore } from '@/store/drawer-store';
import SettingsDrawer from '@/components/Dashboard/SettingsDrawer';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import { clsx } from 'clsx';

const HomeLayout = () => {
  const { toggle: toggleDrawer, isOpen } = useDrawerStore();
  const drawerLink = clsx(
    'group',
    'flex',
    'items-center',
    'rounded-md',
    'bg-gray-900',
    'p-2',
    'text-base',
    'font-medium',
    'leading-6',
    'text-white',
    'transition',
    'duration-150',
    'ease-in-out',
    'hover:text-white',
    'focus:bg-gray-700',
    'focus:outline-none',
  );
  return (
    <>
      <FlashMessage />
      <Drawer open={isOpen} onClose={toggleDrawer} direction="left">
        <nav className="mt-5 space-y-1 px-2">
          <RouterLink to="/" className={drawerLink}>
            Dashboard
          </RouterLink>
          <RouterLink to="/contributions" className={drawerLink}>
            Accounts Contributions (Grid Visualization)
          </RouterLink>
        </nav>
      </Drawer>
      <SettingsDrawer />

      <Navbar />
      <Outlet />
    </>
  );
};

export default HomeLayout;
