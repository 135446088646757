import React from 'react';

import { useDrawerStore } from '@/store/drawer-store';
import { useAuthStore } from '@/store/auth/auth-store';

export default function Navbar() {
  const toggleDrawer = useDrawerStore((state) => state.toggle);
  const { currentUser } = useAuthStore();

  return (
    <nav className="bg-teal flex flex-wrap items-center justify-between bg-black px-6 py-4">
      <div className="block">
        <button
          onClick={toggleDrawer}
          className="border-teal-light flex items-center rounded
                      border px-3 py-2 text-white
                      hover:border-white hover:text-white"
        >
          <svg className="h-3 w-3 fill-current" viewBox="0 0 20 20">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v15z"></path>
          </svg>
        </button>
      </div>
      <div className="mr-6 flex shrink-0 items-center text-white">
        <span className="text-md mr-4 font-semibold tracking-tight">
          {currentUser?.email}
        </span>
        <span className="text-xl font-semibold tracking-tight">
          LGD EmailAPP
        </span>
      </div>
    </nav>
  );
}
