import {err} from 'neverthrow';

import {authApiUrls} from '@/urls/auth-api-urls';
import {getAccessTokenData, useAuthStore} from '@/store/auth/auth-store';
import {isExpired} from '@/utils/token';
import {fetchRefreshToken} from "@/utils/auth/requests/refresh-token-request";

const {setCurrentUser} = useAuthStore.getState();

const refreshTokenIfExpired = async (exp: number | undefined) => {
  if (isExpired(exp)) {
    return await fetchRefreshToken();
  } else {
    return err('token is not expired');
  }
};

const logoutUserWithMessage = async (message: string) => {
  setCurrentUser(null);
  return Promise.reject(message);
};

export const responseExpiredTokenInterceptorReject = async (err: any) => {
  const originalConfig = err.config;
  if (originalConfig.url === '/users/tokens') {
    return Promise.reject('token refresh failed');
  }
  if (err.response.status === 401) {
    const nonRefreshedTokenUrls = [
      authApiUrls.users.me,
      authApiUrls.users.tokens,
      authApiUrls.users.signin,
      authApiUrls.users.signup,
      authApiUrls.users.recoverPassword,
    ];

    if (!nonRefreshedTokenUrls.includes(originalConfig.url || '')) {
      const tokenData = getAccessTokenData();
      const refreshResult = await refreshTokenIfExpired(tokenData?.exp);
      console.log('refreshResult', refreshResult);
      if (refreshResult.isOk()) {
        return Promise.resolve(originalConfig);
      } else {
        return logoutUserWithMessage(err.message);
      }
    } else {
      return logoutUserWithMessage(err.message);
    }
  }

  return Promise.reject(err);
};
