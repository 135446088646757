import { useDashboardViewStore } from '@/store/dashboard-view-store';

const SendsSwitch = () => {
  const {currentSendsView, switchSendsView} = useDashboardViewStore();

  return (
    <div className="flex justify-end items-end mb-px">
        <div className="text-gray-400 border-transparent inline-flex items-center h-[30px] -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap">
          Select View:
        </div>
        <button 
          onClick={() => switchSendsView('Overview')} 
          className={`${currentSendsView === 'Overview' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'} inline-flex items-center h-[30px] px-4 -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap cursor-pointer focus:outline-none`}>
            Overview
        </button>

        <button
          onClick={() => switchSendsView('Campaign')} 
          className={`${currentSendsView === 'Campaign' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'} inline-flex items-center h-[30px] px-4 -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap cursor-pointer focus:outline-none`}>
            Campaign
        </button>

        <button
          onClick={() => switchSendsView('List')}
          className={`disabled:opacity-75 disabled:cursor-not-allowed ${currentSendsView === 'List' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'} inline-flex items-center h-[30px] px-4 -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap cursor-pointer focus:outline-none`}>
            List
        </button>

        <button
          onClick={() => switchSendsView('CTA')}
          className={`disabled:opacity-75 disabled:cursor-not-allowed ${currentSendsView === 'CTA' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'} inline-flex items-center h-[30px] px-4 -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap cursor-pointer focus:outline-none`}>
            CTA
        </button>
    </div>
  )
}

export default SendsSwitch;
