import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import apiV1ExcludeLists from '@/api/Api/V1/ExcludeListsApi';
import { useAuthStore } from '@/store/auth/auth-store';

type DashboardViewState = {
  newRecordThreshold: number;
  excludeList: string[];
  enableExcludeList: boolean;
  currentView: 'Sends' | 'Rampup';
  currentRampupView: 'Overview' | 'Date' | 'Account';
  currentSendsView: 'Overview' | 'Campaign' | 'List' | 'CTA';
  currentCtaView: 'offer' | 'adsense';
};
type DashboardViewActions = {
  switchView: (view: 'Sends' | 'Rampup') => void;
  switchRampupView: (view: 'Overview' | 'Date' | 'Account') => void;
  switchSendsView: (view: 'Overview' | 'Campaign' | 'List' | 'CTA') => void;
  switchCtaView: (view: 'offer' | 'adsense') => void;
  setThreshold: (val: number) => void;
  setExcludeList: (val: string[]) => void;
  toggleExcludeList: () => void;
  saveExcludeList: (excludeList: string[]) => void;
  loadExcludeList: () => void;
};

export const useDashboardViewStore = create<
  DashboardViewActions & DashboardViewState,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer<DashboardViewActions & DashboardViewState>((set) => ({
      newRecordThreshold: 1,
      excludeList: [],
      enableExcludeList: false,
      currentView: 'Sends',
      currentRampupView: 'Overview',
      currentSendsView: 'Overview',
      currentCtaView: 'offer',
      switchView: (view) =>
        set((state) => {
          state.currentView = view;
        }),
      switchRampupView: (view) =>
        set((state) => {
          state.currentRampupView = view;
        }),
      switchSendsView: (view) =>
        set((state) => {
          state.currentSendsView = view;
        }),
      switchCtaView: (view) =>
        set((state) => {
          state.currentCtaView = view;
        }),
      setThreshold: (val) =>
        set((state) => {
          state.newRecordThreshold = val; //threshold new records
        }),
      setExcludeList: (val) =>
        set((state) => {
          state.excludeList = val;
        }),
      toggleExcludeList: () =>
        set((state) => {
          state.enableExcludeList = !state.enableExcludeList;
        }),
      loadExcludeList: async () => {
        try {
          const { accessToken } = useAuthStore.getState();
          const response = await apiV1ExcludeLists.get({headers: { Authorization: 'Bearer ' + accessToken },});
          set((state) => {
            state.excludeList = response;
          });
        } catch (error) {
          console.error('Error loading exclude list:', error);
        }
      },
      saveExcludeList: async (excludeList) => {
        try {
          const { accessToken } = useAuthStore.getState();
          await apiV1ExcludeLists.update({
            headers: { Authorization: 'Bearer ' + accessToken },
            data: { exclude_list: { list_items: excludeList } },
          });
        } catch (error) {
          console.error('Error saving exclude list:', error);
        }
      },
    })),
  ),
);
