import React, { useState } from 'react';
import BaseChart from './BaseChart';
import { TopLevelSpec as VlSpec } from 'vega-lite';

interface ListChartProps {
  data: any[];
  rateField: string;
  yAxisTitle: string;
}

const ListChart: React.FC<ListChartProps> = ({ data, rateField, yAxisTitle }) => {
  const [markType, setMarkType] = useState('line');

  const toggleMarkType = () => {
    setMarkType((prevMarkType) => (prevMarkType === 'line' ? 'bar' : 'line'));
  };

  const uniqueListIds = new Set(data.map((item) => item.listId));
  const showLegend = uniqueListIds.size > 1;
  const legendConfig = {
    labelFontSize: 8,
    titleFontSize: 6,
  };

  const spec: VlSpec = {
    $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
    data: { values: data },
    transform: [
      {
        filter: `datum.${rateField} !== null`,
      },
      {
        calculate: "datetime(datum.date)",
        as: "date",
      },
    ],
    width: 500,
    layer: [
      {
        mark: markType === 'line' ? { type: 'line', interpolate: 'monotone' } : { type: 'bar', size: 5 },
        encoding: {
          x: {
            field: 'date',
            type: 'temporal',
            axis: {
              titleFontSize: 0,
              labelFontSize: 8,
              format: '%m/%d',
            },
          },
          y: {
            field: rateField,
            type: 'quantitative',
            axis: {
              title: yAxisTitle,
              titleFontSize: 10,
              format: '.2f',
            },
          },
          color: {
            field: 'listId',
            type: 'nominal',
            title: 'List',
            legend: showLegend ? legendConfig : null,
          },
          tooltip: [
              {"field": "date", "type": "temporal", "title": "Date", "format": "%m/%d"},
              {"field": rateField, "type": "quantitative", "format": ".2f"},
              {"field": "listId", "type": "nominal", "title": "List"}
          ],
        },
      },
      {
        transform: [
          {
            pivot: 'listId',
            value: rateField,
            groupby: ['date'],
          },
        ],
        mark: { type: 'rule', tooltip: { content: 'data' } },
        encoding: {
          x: { field: 'date', type: 'temporal' },
          opacity: {
            condition: { value: 0.1, param: 'hover', empty: false },
            value: 0,
          }
        },
        params: [
          {
            name: 'hover',
            select: {
              type: 'point',
              fields: ['date'],
              nearest: true,
              on: 'mouseover',
              clear: 'mouseout',
            },
          },
        ],
      },
    ],
  };

  return (
    <div className="relative">
      <button
        onClick={toggleMarkType}
        className="absolute -top-2 -left-2 z-10 bg-blue-500 hover:bg-blue-700 text-white inline px-2 py-1 text-[8px] rounded-full gap-x-2 focus:outline-none"
      >
        {markType === 'line' ? 'Bar' : 'Line'}
      </button>
      <div>
        <BaseChart spec={spec} />
      </div>
    </div>
  );
};

export default ListChart;