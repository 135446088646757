import React from 'react';
import { useEspAccountsStore } from '@/store/esp-accounts-store';

const useAccountsList = () => {
  const { espAccounts, currentEspAccount } = useEspAccountsStore();
  let accounts;
  if (currentEspAccount === null) {
    accounts = espAccounts.map((account, index) => {
      return (
        <li key={index} className="flex min-w-[120px] items-center">
          {account.name}
        </li>
      );
    });
  } else {
    accounts = [
      <li key={Math.random()} className="min-w-[120px]">
        {currentEspAccount.name}
      </li>,
    ];
  }

  return { accounts };
};
export const AccountsNamesColumn = ({rowsCount,}: {
  rowsCount: string | number;
}) => {
  const { accounts } = useAccountsList();

  return (
    <ul
      className="months sticky  grid"
      style={{'grid-template-rows': `repeat(${rowsCount}, var(--square-size))`,}}
    >
      {accounts}
    </ul>
  );
};
