import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { CtaRevenueDataInterface } from  "@/types/cta-revenue"
import { useAuthStore } from '@/store/auth/auth-store';
import { apiV1CtaRevenue } from '@/api';

import { IEspAccount } from '@/types/esp-account';

type CtaRevenueState = {
  loading: boolean;
  ctaRevenueData: CtaRevenueDataInterface[];
  error: string | null;
  startDate: string | null;
  endDate: string | null;
  offerType: 'adsense' | 'offer'; // Or any other types if you have more
};

type CtaRevenueActions = {
  fetchData: (account: IEspAccount | null, ctaView: 'adsense' | 'offer') => void;
  setStartDate: (date: string | null) => void;
  setEndDate: (date: string | null) => void;
};

export const useCtaRevenueStore = create<
  CtaRevenueState & CtaRevenueActions,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer<CtaRevenueState & CtaRevenueActions>((set, getState) => ({
      loading: false,
      ctaRevenueData: [],
      error: null,
      startDate: null,
      endDate: null,
      offerType: 'adsense', // default value
      setStartDate: (date) =>
        set((state) => {
          state.startDate = date;
        }),
      setEndDate: (date) =>
        set((state) => {
          state.endDate = date;
        }),
      fetchData: async (account, ctaView) => {
        try {
          set((state) => {
            state.loading = true;
          });
          const queryParams = {
            start_date: getState().startDate,
            end_date: getState().endDate,
            mail_account_id: account ? account.id : null,
            cta_view: ctaView
          };
          const { accessToken } = useAuthStore.getState();
          const response = await apiV1CtaRevenue.list({
            query: queryParams,
            headers: { Authorization: 'Bearer ' + accessToken },
          });

          const ctaRevenueData = response.data.map((item: CtaRevenueDataInterface) => ({ ...item }));
          set((state) => {
            state.loading = false;
            state.ctaRevenueData = ctaRevenueData;
            state.error = null;
          });
        } catch (err: unknown) {
          set((state) => {
            state.ctaRevenueData = [];
            state.loading = false;
            state.error = `Failed to fetch CTA revenue data: ${err instanceof Error ? err.message : 'Unknown error'}`;
          });
        }
      },
    })),
  ),
);

