import React, { useEffect } from 'react';
import { MultiSelect, ItemRenderer } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import { useListIdStore } from '@/store/esp-lists-store';
import { useRampupStore } from '@/store/rampup-store';

const EspListIdDropdown: React.FC = () => {
  const { rampupData } = useRampupStore();

  const {
    listIds,
    setListIds,
    selectedListIds,
    setSelectedListIds,
    addToListIds,
    removeFromListIds,
  } = useListIdStore();

  useEffect(() => {
    const uniqueListIds = Array.from(
      new Set(
        rampupData.filter((d) => d.listId).map((d) => d.listId.toString()),
      ),
    );
    setListIds(uniqueListIds);
  }, [rampupData]);

  useEffect(() => {
    setSelectedListIds(
      selectedListIds.filter((value) => listIds.includes(value)),
    );
  }, [listIds]);

  const renderListId: ItemRenderer<string> = (
    listId,
    { handleClick, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={listId}
        text={listId.toString()}
        onClick={handleClick}
      />
    );
  };

  const handleTagRemove = (tag: string, index: string) => {
    removeFromListIds(tag);
  };

  const handleItemSelect = (listId: string) => {
    if (selectedListIds.includes(listId)) {
      removeFromListIds(listId);
    } else {
      addToListIds(listId);
    }
  };

  return (
    <div className="mb-px flex items-end justify-end">
      {(listIds.length > 0 || selectedListIds.length > 0) && (
        <>
          <div className="-mb-px mr-1 inline-flex h-[30px] items-center whitespace-nowrap border-b-2 border-transparent bg-transparent text-center text-xs text-gray-400">
            Select List:
          </div>
          <MultiSelect<string>
            fill={true}
            items={listIds}
            itemRenderer={renderListId}
            tagRenderer={(listId) => listId.toString()}
            selectedItems={selectedListIds}
            onItemSelect={handleItemSelect}
            tagInputProps={{
              onRemove: handleTagRemove,
              placeholder: 'Select List IDs...',
            }}
            resetOnSelect={true}
            popoverProps={{ minimal: true }}
          />
        </>
      )}
    </div>
  );
};

export default EspListIdDropdown;
