import React, { FC, ChangeEvent } from 'react';
import {
 Button, Menu, MenuItem, Popover 
} from '@blueprintjs/core';
import { field } from 'vega';

interface Props {
  currentField: string | null;
  fields: string[];
  onFieldSelected: (field: string | null) => void;
}

const FieldsDropdown: FC<Props> = ({
  currentField,
  fields,
  onFieldSelected,
}) => {
  return (
    <>
      <Popover
        minimal={true}
        content={
          <Menu>
            {fields.map((field) => (
              <MenuItem
                active={field == currentField}
                selected={field == currentField}
                key={field}
                text={field}
                onClick={() => onFieldSelected(field)}
              />
            ))}
          </Menu>
        }
        placement="bottom"
      >
        <Button
          alignText="left"
          icon="applications"
          rightIcon="caret-down"
          text={currentField ?? 'Select Field'}
        />
      </Popover>
    </>
  );
};

export default FieldsDropdown;
