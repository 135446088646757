import React, { useState, useRef, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Menu, MenuItem } from "@blueprintjs/core";
import { Table2, Cell, Column, ColumnHeaderCell } from "@blueprintjs/table";
import { RampupDataInterface } from '@/types/rampup';
import { useRampupStore } from '@/store/rampup-store';

const DISPLAY_COLUMNS = [
    { key: 'listName', display: 'Name' },
    { key: 'listId', display: 'List Id' },
    { key: 'newRecordsSum', display: 'New Records' },
    { key: 'newSubsSum', display: 'New Subs' },
    { key: 'newSubsOpenSum', display: 'Opens' },
    { key: 'newSubsClicksSum', display: 'Clicks' },
    { key: 'newSubsUnsubSum', display: 'Unsubs' },
    { key: 'newSubsOr', display: 'New Sub_OR' },
    { key: 'newSubsCr', display: 'New Sub_CR' },
    { key: 'newSubsUr', display: 'D UR' },
    { key: 'totalSubsSum', display: 'Total Subs' },
    { key: 'totalOpensSum', display: 'Total Opens' },
    { key: 'totalClicksSum', display: 'Total Clicks' },
    { key: 'totalUnsubsSum', display: 'Total Unsubs' },
    { key: 'totalOr', display: 'T OR' },
    { key: 'totalCr', display: 'T CR' },
    { key: 'totalSubsSumDelta', display: 'Delt Subs' },
    { key: 'totalOpensSumDelta', display: 'Delt Opens' },
    { key: 'totalClicksSumDelta', display: 'Delt Clicks' },
    { key: 'deltaOr', display: 'Del OR' }
  ];

interface RampupDateTableProps {
    data: RampupDataInterface[];
}

export const RampupDateTable: React.FC<RampupDateTableProps> = ({ data }) => {
    const { threshold } = useRampupStore();
    const tableRef = useRef<Table2>(null);
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const [tableKey, setTableKey] = useState(nanoid());
    const columnsCount = data[0] ? DISPLAY_COLUMNS.length : 0;
    const [columnWidths, setColumnWidths] = useState<number[]>(new Array(columnsCount).fill(150));

    // Sorting state and function
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const handleSort = (column: keyof RampupDataInterface) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
        setTableKey(nanoid());
    };

    const sortedData = [...data].sort((a, b) => {
        if (!sortColumn) return 0;
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];
        if (valueA === valueB) return 0;
        const order = valueA > valueB ? 1 : -1;
        return sortDirection === 'asc' ? order : -order;
    });

    const shouldHighlightCell = (value: any, key: string): boolean => {
        return (key.includes("Ur") || key.includes("Or") || key.includes("Cr")) && typeof value === "number" && value < threshold;
    };

    const updateColumnsWidth = (newWidths: number[]) => {
        setColumnWidths(newWidths);
        setTableKey(nanoid());
    };

    const autoSizeColumn = (columnIndex: number | null) => {
        let newWidths = [...columnWidths];
        if (columnIndex === null) {
            newWidths = newWidths.map((_width, index) => tableRef.current?.locator?.getWidestVisibleCellInColumn(index) || 150);
        } else {
            newWidths[columnIndex] = tableRef.current?.locator?.getWidestVisibleCellInColumn(columnIndex) || 150;
        }
        updateColumnsWidth(newWidths);
    };

    const shrinkColumnsToFitContainer = () => {
        const containerWidth = tableWrapperRef.current?.offsetWidth || 0;
        const equalWidth = containerWidth / columnsCount;
        const newWidths = new Array(columnsCount).fill(equalWidth);
        updateColumnsWidth(newWidths);
    };

    useEffect(() => {
        shrinkColumnsToFitContainer();
    }, []);

    const renderColumnHeader = (name: string, columnKey: keyof RampupDataInterface) => {
        const menuRenderer = () => (
            <Menu>
                <MenuItem icon="double-chevron-right" onClick={shrinkColumnsToFitContainer} text="Fit to container" />
                <MenuItem icon="double-chevron-down" onClick={() => autoSizeColumn(DISPLAY_COLUMNS.findIndex(col => col.key === columnKey))} text="Auto Size Column" />
                <MenuItem icon="double-chevron-left" onClick={() => autoSizeColumn(null)} text="Auto Size All Columns" />
                <MenuItem icon={sortDirection === 'asc' ? "sort-asc" : "sort-desc"} onClick={() => handleSort(columnKey)} text={`Sort ${sortDirection === 'asc' ? "Descending" : "Ascending"}`} />
            </Menu>
        );
        return (
            <ColumnHeaderCell className="font-bold" name={name} menuRenderer={menuRenderer} 
              icon={sortColumn === columnKey ? (sortDirection === 'asc' ? 'sort-asc' : 'sort-desc') : 'double-caret-vertical'} onClick={() => handleSort(columnKey)} />
        );
    };

    const columns = DISPLAY_COLUMNS.map((column, index) => (
        <Column
            key={index}
            columnHeaderCellRenderer={() => renderColumnHeader(column.display, column.key)}
            cellRenderer={(rowIndex: number) => {
                const shouldAppendPercentage = ["New Sub_OR", "New Sub_CR", "D UR", "T OR", "T CR", "Del OR"].includes(column.display);
                const cellData = sortedData[rowIndex][column.key];
                const cellValue = cellData + (shouldAppendPercentage ? '%' : '');
                const highlight = shouldHighlightCell(cellData, column.key);
                return <Cell className={highlight ? 'bg-red-500 text-white' : ''}>{cellData !== null ? cellValue : 'N/A'}</Cell>;
            }}
        />
    ));

    return (
        <div ref={tableWrapperRef} className={'border-gray-100 overflow-auto max-h-[420px]'}>
            <Table2
                key={tableKey}
                ref={tableRef}
                numRows={sortedData.length}
                columnWidths={columnWidths}
                enableRowHeader={false}
            >
                {columns}
            </Table2>
        </div>
    );
};

export default RampupDateTable;
