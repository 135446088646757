// JsFromRoutes CacheKey 26f3ab71dc6148610f00225d881cf356
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  stats: definePathHelper('get', '/api/v1/charts/stats'),
  statsOverview: definePathHelper('get', '/api/v1/charts/stats_overview'),
  statsByAccount: definePathHelper('get', '/api/v1/charts/esp_accounts/:name/stats'),
  data: definePathHelper('get', '/api/v1/charts/data'),
  dataByProvider: definePathHelper('get', '/api/v1/charts/esp_providers/:id/data'),
  dataByAccount: definePathHelper('get', '/api/v1/charts/esp_accounts/:id/data'),
}
