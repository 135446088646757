import React from 'react';
import { Link } from 'react-router-dom';
import { SignUpForm } from '@/components/auth/SignUpForm';

export function SignUpPage() {
  return (
    <div className="">
      <div className="container mx-auto grid grid-cols-5">
        <div className="col-span-1 min-h-[100vh]"></div>
        <div className="col-span-3  mt-[5px] bg-white px-[72px] pt-[120px]">
          <h1
            className="font-['Avenir'] text-[28px] font-extrabold leading-[30px] text-zinc-800
                         lg:leading-[44px]"
          >
            Create your account
          </h1>
          <SignUpForm />
          <div className="mt-[24px] flex justify-center">
            <span className="mr-[4px]">Already have an account?</span>
            <Link to="/login" className="text-blue-700">
              Log in
            </Link>
          </div>
        </div>

        <div className="col-span-1 min-h-[100vh]"></div>
      </div>
    </div>
  );
}
