import { ContributionDay } from '@/components/charts/ContributionGraph/ContributionDay';
import { ContributionGraphFilterableFields } from '@/types/contribution-graph-filterable-fields';
import React, { FC } from 'react';
import { StatsDataInterface } from '@/types/stats';

interface AccountsDataColumnProps {
  key: number;
  accounts: { [key: string]: StatsDataInterface };
  currentField: string | null;
  threshold: number;
}

export const AccountsDataColumn: FC<AccountsDataColumnProps> = ({
  key,
  accounts,
  currentField,
  threshold,
}) => {
  return (
    <ul key={key} className="months mr-[10px] flex-col">
      {Object.entries(accounts).map(([accName, accData]) => {
        return (
          <ContributionDay
            threshold={threshold}
            day={accData}
            key={accName}
            mainField={
              currentField ?? ('CR' as ContributionGraphFilterableFields)
            }
          />
        );
      })}
    </ul>
  );
};
