import React from 'react';

export function BorderedButton({
  content,
  additionalClasses,
}: {
  content: React.JSX.Element;
  additionalClasses?: string;
}) {
  return (
    <button
      className={`inline-flex h-10
      items-center justify-center
      gap-4 rounded-sm border
      border-zinc-800 bg-transparent
      px-4 py-2 ${additionalClasses}`}
    >
      {content}
    </button>
  );
}
