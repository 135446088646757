import React, { useState } from 'react';
import { useDashboardViewStore } from '@/store/dashboard-view-store';

const DashboardSwitch = () => {
  const {currentView, switchView} = useDashboardViewStore(); // Initially set to 'Sends'

  return (
    <div className="flex overflow-hidden bg-white border divide-x rounded-lg">
      
      <button 
        onClick={() => switchView('Sends')}
        className={`px-4 py-2 font-medium text-gray-600 outline-none transition-colors duration-200 sm:px-6 hover:bg-gray-100 ${currentView === 'Sends' ? 'bg-gray-200' : ''}`}
      >
        Sends
      </button>

      <button 
        onClick={() => switchView('Rampup')}
        className={`px-4 py-2 font-medium text-gray-600 outline-none transition-colors duration-200 sm:px-6  hover:bg-gray-100 ${currentView === 'Rampup' ? 'bg-gray-200' : ''}`}
      >
        Rampup
      </button>

    </div>
  )
}

export default DashboardSwitch;
