import React, { useEffect, useState } from 'react';
import SpinLoader from '@/components/SpinLoader';
import AccountsTables from "@/components/tables/AccountsTables";
import RampupOverviewTable from "@/components/tables/RampupOverviewTable";
import DateTables from '@/components/tables//DateTables';
import RampupSwitch from '@/components/Dashboard/RampupSwitch';
import EspListIdDropdown from '@/components/Dashboard/EspListIdDropdown';
import { useRampupStore } from '@/store/rampup-store';
import { useStatsStore } from '@/store/stats-store';
import { useEspAccountsStore } from '@/store/esp-accounts-store';
import { useDashboardViewStore } from '@/store/dashboard-view-store';
import './dashboard_table_view.css'

import { getSevenDaysAgo } from '@/utils/GetSevenDaysAgo';

const RampupView: React.FC = () => {
  const {
    loading,
    rampupData,
    startDate,
    endDate,
    fetchRampupData,
    fetchDataForAccount,
    newRecordsThreshold,
    excludeList,
    setStartDate
  } = useRampupStore();
  const [lastLoad, setLastLoad] = useState('');
  const { setStartDate: setPickerDate } = useStatsStore();
  const { currentRampupView } = useDashboardViewStore();
  const { currentEspAccount, espAccounts, setCurrentEspAccount } = useEspAccountsStore();

  useEffect(() => {
    const currentDate: string = new Date().toISOString().split('T')[0];
    const rampupStartDate = getSevenDaysAgo(null, currentDate)
    if (!startDate)
      setStartDate(rampupStartDate)
      setPickerDate(rampupStartDate)
  }, []);

  useEffect(() => {
    if (currentEspAccount) {
      fetchDataForAccount(currentRampupView, currentEspAccount);
    } else {
      if (lastLoad !== currentRampupView && ['Date', 'Account'].includes(currentRampupView) && espAccounts[0]) {
        let currentAccount = espAccounts[0]
        setCurrentEspAccount(currentAccount)
        fetchDataForAccount(currentRampupView, currentAccount);
      } else {
        fetchRampupData(currentRampupView);
      }
      if (lastLoad !== currentRampupView)
        setLastLoad(currentRampupView)
    }
  }, [currentEspAccount, startDate, endDate, currentRampupView, excludeList, newRecordsThreshold]);

  let TableComponent = currentRampupView === 'Overview' ? RampupOverviewTable : AccountsTables;
  if (currentRampupView === 'Date') TableComponent = DateTables

  return (
    <div className="dashboard_table_view -mx-2 flex w-full flex-wrap">
      <div className="w-full mt-4 flex justify-between items-end">
        <div className="max-w-[50%]">
          <EspListIdDropdown/>
        </div>
        <div className="">
          <RampupSwitch />
        </div>
      </div>
      {loading && (
        <div className="flex w-full min-h-[160px] items-center justify-center">
          <SpinLoader />
        </div>
      )}
      {!loading && rampupData.length > 0 && (
        <>
          <div className="w-full mb-8">
            <TableComponent data={JSON.parse(JSON.stringify(rampupData))} />
          </div>
        </>
      )}
    </div>
  );
};

export default RampupView;
