import React, { useState, useRef, useEffect } from 'react';
import { Cell, Column, Table2, ColumnHeaderCell } from '@blueprintjs/table';
import { Menu, MenuItem } from "@blueprintjs/core";
import { nanoid } from 'nanoid';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';

interface Data {
  date: string;
  totalRevenue: number;
  totalSentSum: number;
  totalDeliveredSum: number;
  uniqueOpensSum: number;
  uniqueClicksSum: number;
  totalClicksSum: number;
  unsubsSum: number;
  bouncesSum: number;
  orRate: number;
  crRate: number;
  urRate: number;
  drRate: number;
  complainRate: number;
  bounceRate: number;
  rpc: number;
  ecpm: number;
}


interface DataTableProps {
  data: Data[];
}

const SendsOverviewSummaryTable: React.FC<DataTableProps> = ({ data }) => {
  const tableRef = useRef<Table2>(null);
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [tableKey, setTableKey] = useState(nanoid());

  // Sorting state and functions
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const handleSort = (column: keyof Data) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
    setTableKey(nanoid());  // to force re-render
  };

  const sortedData = [...data].sort((a, b) => {
    if (!sortColumn) return 0;
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];
    if (valueA === valueB) return 0;
    const order = valueA > valueB ? 1 : -1;
    return sortDirection === 'asc' ? order : -order;
  });

  const DISPLAY_COLUMNS = [
    { key: 'date', display: 'Date' },
    { key: 'totalSentSum', display: 'Sent' },
    { key: 'totalDeliveredSum', display: 'Delivered' },
    { key: 'uniqueOpensSum', display: 'Opens' },
    { key: 'uniqueClicksSum', display: 'Clicks' },
    { key: 'totalClicksSum', display: 'Total Clicks' },
    { key: 'unsubsSum', display: 'Unsubs' },
    { key: 'bouncesSum', display: 'Bounces' },
    { key: 'totalRevenue', display: 'Revenue' },
    { key: 'orRate', display: 'OR' },
    { key: 'crRate', display: 'CR' },
    { key: 'urRate', display: 'UR' },
    { key: 'drRate', display: 'DR' },
    { key: 'complainRate', display: 'Complain' },
    { key: 'bounceRate', display: 'Bounce' },
    { key: 'rpc', display: 'RPC' },
    { key: 'ecpm', display: 'eCPM' },
  ];
  

  const columnsCount = DISPLAY_COLUMNS.length;
  const [columnWidths, setColumnWidths] = useState<number[]>(new Array(columnsCount).fill(150));

  const formatCellValue = (columnKey: keyof Data, value: any) => {
    switch (columnKey) {
      case 'totalRevenue':
      case 'rpc':
      case 'ecpm':
        return `$${Number(value).toFixed(2)}`;
      case 'orRate':
      case 'crRate':
      case 'urRate':
      case 'drRate':
      case 'complainRate':
      case 'bounceRate':
        return `${Number(value).toFixed(2)}%`;
      default:
        return (typeof value === 'number' && value % 1 !== 0 ? Number(value).toFixed(2) : value);
    }
  };
  

  const autoSizeColumn = (columnIndex: number | null) => {
    const newWidths = [...columnWidths];
    if (columnIndex === null) {
      for (let i = 0; i < columnsCount; i++) {
        newWidths[i] = tableRef.current?.locator?.getWidestVisibleCellInColumn(i) || 150;
      }
    } else {
      newWidths[columnIndex] = tableRef.current?.locator?.getWidestVisibleCellInColumn(columnIndex) || 150;
    }
    setColumnWidths(newWidths);
    setTableKey(nanoid());
  };

  const shrinkColumnsToFitContainer = () => {
    const containerWidth = tableWrapperRef.current?.offsetWidth || 0;
    const equalWidth = containerWidth / columnsCount;
    const newWidths = new Array(columnsCount).fill(equalWidth);
    setColumnWidths(newWidths);
  };

  const renderColumnHeader = (name: string, columnKey: keyof Data) => {
    const menuRenderer = () => (
      <Menu>
          <MenuItem icon="double-chevron-right" onClick={shrinkColumnsToFitContainer} text="Fit to container" />
          <MenuItem icon="double-chevron-down" onClick={() => autoSizeColumn(DISPLAY_COLUMNS.findIndex(col => col.key === columnKey))} text="Auto Size Column" />
          <MenuItem icon="double-chevron-left" onClick={() => autoSizeColumn(null)} text="Auto Size All Columns" />
          <MenuItem icon={sortDirection === 'asc' ? "sort-asc" : "sort-desc"} onClick={() => handleSort(columnKey)} text={`Sort ${sortDirection === 'asc' ? "Descending" : "Ascending"}`} />
      </Menu>
    );
    return (
      <ColumnHeaderCell name={name} menuRenderer={menuRenderer}
        icon={sortColumn === columnKey ? (sortDirection === 'asc' ? 'sort-asc' : 'sort-desc') : 'double-caret-vertical'} onClick={() => handleSort(columnKey)} />
    );
  };

  useEffect(() => {
    shrinkColumnsToFitContainer();
  }, []);

  return (
    <div ref={tableWrapperRef} className={'border-gray-100 overflow-auto max-h-[420px]'}>
      {data && data[0] && columnWidths.length === DISPLAY_COLUMNS.length && (
        <Table2
          key={tableKey}
          ref={tableRef}
          numRows={sortedData.length}  // Notice I changed this to sortedData
          columnWidths={columnWidths}
          enableRowHeader={false}
        >
          {DISPLAY_COLUMNS.map((column, index) => (
            <Column
              key={index}
              columnHeaderCellRenderer={() => renderColumnHeader(column.display, column.key)}
              cellRenderer={(rowIndex: number) => {
                const cellData = sortedData[rowIndex][column.key]; // Again, I changed this to sortedData
                const formattedData = formatCellValue(column.key, cellData);
                return <Cell>{formattedData !== null ? formattedData : 'N/A'}</Cell>;
              }}
            />
          ))}
        </Table2>
      )}
    </div>
  );
};

export default SendsOverviewSummaryTable;
