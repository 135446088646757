import React, { useEffect } from 'react';
import { Label } from '@blueprintjs/core';
import { useEspProvidersStore } from '@/store/esp-provider-store';
import EspAccountDropdown from '@/components/Dashboard/EspAccountDropdown';
import DateRangeSelector from '@/components/Dashboard/DateRangeSelector';
import DashboardSwitch from './DashboardSwitch';

interface DashboardHeadingPanelProps {
  mode?: 'esp' | 'ecpm' | 'default'
}



const DashboardHeadingPanel: React.FC<DashboardHeadingPanelProps> = ({ mode = 'default' }) => {
  const { fetchEspProviders, currentEspProvider } = useEspProvidersStore();

  useEffect(() => {
    fetchEspProviders();
  }, [fetchEspProviders]);

  return (
    <div className="dashboard_heading_panel bg-teal flex flex-wrap items-center justify-between bg-white">
      <div className="flex text-black">
        { mode == 'esp' ? <DashboardSwitch /> : '' }
        <div>
          {currentEspProvider?.id && <span className="ml-4">ID: </span>}
          {currentEspProvider?.id}
        </div>
      </div>

      <div className="flex">
        <DateRangeSelector />
        <div className="px-2">
          <Label>
            Account
            <EspAccountDropdown />
          </Label>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeadingPanel;