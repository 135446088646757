import React, { useEffect } from 'react';
import Drawer from 'react-modern-drawer';
import { NumericInput, TagInput, Switch } from "@blueprintjs/core";

import { useDashboardViewStore } from '@/store/dashboard-view-store';
import { useDrawerStore } from '@/store/drawer-store';
import { useEcpmStore } from '@/store/ecpm-store';
import { useRampupStore } from '@/store/rampup-store';
import { useStatsStore } from '@/store/stats-store';
import 'react-modern-drawer/dist/index.css';
import { useActionData } from 'react-router';

const SettingsDrawer: React.FC = () => {
  const { toggleSettings, isSettingsOpen } = useDrawerStore();
  const {
    newRecordThreshold: threshold,
    setThreshold,
    excludeList,
    setExcludeList,
    enableExcludeList,
    toggleExcludeList,
    loadExcludeList,
    saveExcludeList
  } = useDashboardViewStore();

  const { setExcludeList: setEcpmExcludeList, setTotalListThreshold: setEcpmThreshold } = useEcpmStore();
  const { setExcludeList: setStatsExcludeList } = useStatsStore();
  const { setExcludeList: setRampupExcludeList, setNewRecordsThreshold: setRapmupThreshold } = useRampupStore();

  const handleThresholdChange = (valueAsNumber: number, valueAsString: string) => {
    if (valueAsNumber >= 0 && valueAsNumber <= 100) {
      setThreshold(valueAsNumber);
    } else {
      setThreshold(+valueAsString);
    }
  };

  useEffect(() => {
    loadExcludeList()
  }, []);

  useEffect(() => {
    saveExcludeList(excludeList)
    let newLists = enableExcludeList ? excludeList : [];
    setEcpmExcludeList(newLists);
    setStatsExcludeList(newLists);
    setRampupExcludeList(newLists);
  }, [excludeList, enableExcludeList]);

  useEffect(() => {
    setEcpmThreshold(threshold);
    setRapmupThreshold(threshold);
  }, [threshold]);

  return (
    <>
      <Drawer open={isSettingsOpen} onClose={toggleSettings} direction="right">
        <div className="mt-5 space-y-4 px-4">
          <h1 className="py-2 text-lg">Data settings</h1>
          <div className="p-2">
            <div className="">
              <Switch
                label="Toggle Seeds Suppression"
                checked={enableExcludeList}
                onChange={toggleExcludeList}
              />
            </div>

            <div className="">
              <label className="block text-sm font-medium text-gray-700 py-1">Seed lists</label>
              <TagInput
                values={excludeList}
                onChange={(newTags: React.ReactNode[]) => setExcludeList(newTags as string[])}
                placeholder="Add new tags..."
              />
            </div>

          </div>
          <div className="p-2">
            <label className="block text-sm font-medium text-gray-700 py-1">Minimum records threshold</label>
            <NumericInput
              value={threshold}
              small={true}
              onValueChange={handleThresholdChange}
              min={0}
              max={100}
              placeholder="0 - 100"
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SettingsDrawer;
