import { RampupDataInterface } from '@/types/rampup';
import axios from 'axios';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IEspAccount } from '@/types/esp-account';
import { apiV1Rampup } from '@/api';
import { useAuthStore } from '@/store/auth/auth-store';

type RampupState = {
  loading: boolean;
  rampupData: RampupDataInterface[];
  excludeList: string[];
  error: string | null;
  startDate: string | null;
  endDate: string | null;
  threshold: number;
  newRecordsThreshold: number;
};

type ViewMode = 'Date' | 'Account' | 'Overview';

type RampupActions = {
  setStartDate: (date: string | null) => void;
  setEndDate: (date: string | null) => void;
  setThreshold: (val: number) => void;
  setNewRecordsThreshold: (val: number) => void;
  setExcludeList: (val: string[]) => void;
  fetchDataForAccount: (view_mode: ViewMode, account: IEspAccount) => void;
  fetchRampupData: (view_mode: ViewMode) => void;
};

export const useRampupStore = create<
  RampupState & RampupActions,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer<RampupState & RampupActions>((set, getState) => ({
      startDate: null,
      endDate: null,
      excludeList: [],
      threshold: 20,
      newRecordsThreshold: 1,
      setStartDate: (date) =>
        set((state) => {
          state.startDate = date;
        }),
      setEndDate: (date) =>
        set((state) => {
          state.endDate = date;
        }),
      setThreshold: (val) =>
        set((state) => {
          state.threshold = val;
        }),
      setNewRecordsThreshold: (val) =>
        set((state) => {
          state.newRecordsThreshold = val;
        }),
      setExcludeList: (val) =>
        set((state) => {
          state.excludeList = val;
        }),
      loading: false,
      rampupData: [],
      error: null,
      fetchDataForAccount: async (
        view_mode: ViewMode,
        account: IEspAccount,
      ) => {
        try {
          set((state) => {
            state.loading = true;
          });
          const { accessToken } = useAuthStore.getState();
          const queryParams = {
            start_date: getState().startDate,
            end_date: getState().endDate,
            exclude_list: getState().excludeList.join(','),
            records_threshold: getState().newRecordsThreshold,
            view_mode: view_mode.toLowerCase(),
          };
          const response = await apiV1Rampup.dataByAccount({
            params: { 
              name: account.name,
              query: queryParams,
            },
            headers: { Authorization: 'Bearer ' + accessToken },
          });

          const rampupData = response.map((item: RampupDataInterface) => ({...item,}));
          set((state) => {
            state.loading = false;
            state.rampupData = rampupData;
            state.error = null;
          });
        } catch (err: unknown) {
          console.log('err', err);
          set((state) => {
            state.loading = false;
            state.rampupData = [];
            if (err instanceof Error) {
              state.error = `Failed to fetch chart data: ${err.message}`;
            } else {
              state.error = `Failed to fetch chart data`;
            }
          });
        }
      },
      fetchRampupData: async (view_mode: ViewMode) => {
        try {
          set((state) => {
            state.loading = true;
          });
          const { accessToken } = useAuthStore.getState();
          const queryParams = {
            start_date: getState().startDate,
            end_date: getState().endDate,
            exclude_list: getState().excludeList.join(','),
            records_threshold: getState().newRecordsThreshold,
            view_mode: view_mode.toLowerCase(),
          };
          const response = await apiV1Rampup.data({
            query: queryParams,
            headers: { Authorization: 'Bearer ' + accessToken },
          });
          const rampupData = response.map((item: RampupDataInterface) => ({...item,}));
          set((state) => {
            state.loading = false;
            state.rampupData = rampupData;
            state.error = null;
          });
        } catch (err: unknown) {
          console.log('err', err);
          set((state) => {
            state.rampupData = [];
            state.loading = false;
            if (err instanceof Error) {
              state.error = `Failed to fetch chart data: ${err.message}`;
            } else {
              state.error = `Failed to fetch chart data`;
            }
          });
        }
      },
    })),
  ),
);
