import {
 err, ok, Result, ResultAsync 
} from 'neverthrow';
import apiV1Users from '@/api/Api/V1/UsersApi';
import { useAuthStore } from '@/store/auth/auth-store';
import { User, userSchema } from '@/schemas/user';

const UnhandledErrorMessage = 'Fetch Error: Please contact the support';
type fetchMeSuccessResult = {
  user: User;
};
type fetchMeFailureResult = {
  message: string;
};
export const fetchMe = async (): Promise<
  Result<fetchMeSuccessResult, fetchMeFailureResult>
> => {
  try {
    const { accessToken } = useAuthStore.getState();
    const meRequest = apiV1Users.me({headers: { Authorization: 'Bearer ' + accessToken },});
    const meResponse = ResultAsync.fromPromise(meRequest, (error) => {
      return error as Error;
    });

    const result = meResponse.match(
      (response) => {
        const userJson = JSON.parse(response.data);
        const user = userSchema.safeParse(userJson['user']);
        if (user.success) return ok({ user: user.data });
        if (!user.success) return err({ message: user?.error?.message });

        return err({ message: UnhandledErrorMessage });
      },
      (error: Error) => {
        return err({ message: error.message });
      },
    );
    return result;
  } catch (error) {
    console.log('error', error);
    if (error instanceof Error) {
      return err({ message: error.message });
    }
    return err({ message: UnhandledErrorMessage });
  }
};
