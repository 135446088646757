import React, { useEffect } from 'react';
import SpinLoader from '@/components/SpinLoader';
import ClickRateOpenRateChart from '@/components/charts/ClickRateOpenRateChart';
import ClicksAndSendsChart from '@/components/charts/ClicksAndSendsChart';
import URAndCURChart from '@/components/charts/URandCURChart';
import SendsCampaignTables from '@/components/tables/SendsCampaignTables';
import ListChart from '@/components/charts/ListChart';
import RateCharts from '@/components/charts/RateCharts';
import { useStatsStore } from '@/store/stats-store';
import SendsSwitch from './SendsSwitch';
import CtaSwitch from './CtaSwitch';
import SendsOverviewTable from '@/components/tables/SendsOverviewTable';
import SendsListTables from '@/components/tables/SendsListTables';
import CtaTables from '@/components/tables/CtaTables';
import { useDashboardViewStore } from '@/store/dashboard-view-store';
import { useEcpmStore } from '@/store/ecpm-store';
import { getMonthAgo } from '@/utils/GetSevenDaysAgo';
import { useEspAccountsStore } from '@/store/esp-accounts-store';


const SendsView: React.FC = () => {

  const { currentSendsView} = useDashboardViewStore();
  const { currentEspAccount } = useEspAccountsStore();

  const {
    loading,
    chartData,
    statsData,
    setStartDate,
  } = useStatsStore();
 
  const {
    loading: ecpmLoading,
    error: ecpmError,
    ecpmChartData,
    startDate,
    endDate,
    excludeList,
    recordsThreshold,
    fetchChartByList,
  } = useEcpmStore();


  useEffect(() => {
    if (currentSendsView === 'List') {
      console.log('FETCH LIST')
      fetchChartByList(currentEspAccount);
    }
  }, [currentEspAccount, startDate, endDate, excludeList, recordsThreshold, currentSendsView]);
  useEffect(() => {
    if (currentSendsView !== 'Overview') {
      const currentDate: string = new Date().toISOString().split('T')[0];
      const newStartDate = getMonthAgo(null, currentDate)
      setStartDate(newStartDate)
    }
  }, [currentSendsView]);

  let TableComponent = currentSendsView === 'Overview' ? SendsOverviewTable : SendsCampaignTables;
  if (currentSendsView === 'List') TableComponent = SendsListTables
  if (currentSendsView === 'CTA') TableComponent = CtaTables
  return (
    <div className="dashboard_table_view -mx-2 flex w-full flex-wrap">
      <div className="container mx-auto w-full px-4 mb-8">
        <div className="w-full mt-4 flex justify-between items-end">
          <div className="max-w-[50%]">
            {currentSendsView === 'CTA' && (
              <CtaSwitch />
            )}
          </div>
          <div>
            <SendsSwitch />
          </div>
        </div>
        <TableComponent/>
      </div>
      {loading && (
        <div className="flex w-full min-h-[160px] items-center justify-center">
          <SpinLoader />
        </div>
      )}
      {currentSendsView === 'List' && (
        <>
          {!ecpmLoading && ecpmChartData.length > 0 && (
            <>
              <div className="w-full px-2">
                <ListChart 
                  data={JSON.parse(JSON.stringify(ecpmChartData))}
                  rateField="ecpm"
                  yAxisTitle="ecpm" />
              </div>
            </>
          )}
        </>
      )}
      {currentSendsView !== 'List' && (
        <>
          {!loading && statsData.length > 0 && (
            <>
              <div className="w-full px-2">
                <ClickRateOpenRateChart
                  data={JSON.parse(JSON.stringify(statsData))}
                />
              </div>
              <div className="w-full px-2">
                <ClicksAndSendsChart
                  data={JSON.parse(JSON.stringify(statsData))}
                />
              </div>
              <div className="w-full px-2">
                <URAndCURChart
                  data={JSON.parse(JSON.stringify(statsData))}
                />
              </div>
            </>
          )}
          <RateCharts />
        </>
      )}
    </div>
  );
};

export default SendsView;
