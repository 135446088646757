import React from 'react';
import { LoginForm } from '@/components/auth/LoginForm';
import { Link } from 'react-router-dom';

export function LoginPage() {
  return (
    <div className="">
      <div className="container mx-auto grid grid-cols-5">
        <div className="col-span-1 "></div>
        <div className="col-span-3 mt-[5px] bg-white px-[72px] pt-[120px]">
          <h1
            className="font-['Avenir'] text-[28px] font-extrabold leading-[30px] text-zinc-800
                         lg:leading-[44px]"
          >
            Log in
          </h1>
          <LoginForm />
          <div className="mt-[24px] flex justify-center">
            <span className="mr-[4px]">Don’t have an acount?</span>
            <Link to="/sign-up" className="text-blue-700">
              Sign up
            </Link>
          </div>
        </div>
        <div className="col-span-1 "></div>
      </div>
    </div>
  );
}
