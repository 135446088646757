export function getSevenDaysAgo(endDate: string | null, currentDate: string) {
  const dateForCalculation: Date = new Date(endDate || currentDate);
  dateForCalculation.setDate(dateForCalculation.getDate() - 7);
  return dateForCalculation.toISOString().split('T')[0];
}

export function getMonthAgo(endDate: string | null, currentDate: string) {
  const dateForCalculation: Date = new Date(endDate || currentDate);
  dateForCalculation.setDate(dateForCalculation.getDate() - 30);
  return dateForCalculation.toISOString().split('T')[0];
}
