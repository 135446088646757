import React, { useEffect, useState } from 'react';
import { Tooltip } from '@blueprintjs/core';
import { StatsDataInterface } from '@/types/stats';
import thresholdInput from '@/components/charts/ContributionGraph/ThresholdInput';
import { ContributionGraphFilterableFields } from '@/types/contribution-graph-filterable-fields';

const useDayData = (day: StatsDataInterface, field: string) => {
  const [dayData, setDayData] = useState<string | number>(0);

  useEffect(() => {
    if (day && field) {
      const data = day[field];
      if (data) {
        setDayData(data);
      }
    }
  }, [day, field]);

  return { dayData };
};

const useDataLevel = (data: string | number, threshold: number) => {
  const dataLevel = Number(data) < threshold ? 0 : 3;
  return { dataLevel };
};

const truncate = (str: string) => {
  return str.length > 5 ? str.substring(0, 4) + '...' : str;
};
export const ContributionDay = ({
  day,
  key,
  threshold,
  mainField,
}: {
  day: StatsDataInterface;
  key: string;
  threshold: number;
  mainField: ContributionGraphFilterableFields;
}) => {
  const { dayData } = useDayData(day, mainField);
  const { dataLevel } = useDataLevel(dayData, threshold);
  return (
    <Tooltip
      key={key}
      openOnTargetFocus={true}
      compact={true}
      content={
        <div>
          <ul>
            {Object.entries(day).map(([key, value], index) => {
              return (
                <li key={index}>
                  {key}: {value}
                </li>
              );
            })}
          </ul>
        </div>
      }
    >
      <li
        className="flex h-[--square-size]
                   w-[--square-size] items-center justify-center p-[5px] text-white"
        data-level={dataLevel}
      >
        {truncate(`${dayData}`)}
      </li>
    </Tooltip>
  );
};
