import React from 'react';
import AccordionItem from '../AccordionItem';
import RampupAccountTable from './RampupAccountTable';
import { RampupDataInterface } from '@/types/rampup';
import { useEspAccountsStore } from '@/store/esp-accounts-store';
import { useListIdStore } from '@/store/esp-lists-store';

interface AccountTableProps {
  data: RampupDataInterface[];
}

const AccountsTables: React.FC<AccountTableProps> = ({ data }) => {
  const {
    espAccounts
  } = useEspAccountsStore();
  const {
    selectedListIds,
  } = useListIdStore();

  const accountsObject: Record<number, string> = {};
  espAccounts.map(item => accountsObject[item.id] = item.name);
  // Extract all unique account IDs from the data
  const accountIDs = [...new Set(data.map(item => item.mailAccountsId))];

  let filteredData = data
  if (selectedListIds.length > 0) {
    filteredData = data.filter(d => selectedListIds.includes(d.listId.toString()))
    console.log('filteredData', filteredData)
  }
  const renderMultipleAccounts = (ids: number[]) => {
    return ids.map(accountId => {
      const accountData = filteredData.filter(d => d.mailAccountsId === accountId);
      const accountComponent = accountData.length === 0 ? (<></>) : (
        <AccordionItem
          title={`Account: #${accountId} - ${accountsObject[accountId]}`}
          content={<RampupAccountTable data={accountData} />}
        />
      )
      return accountComponent
    })
  }

  return (
    <div className="space-y-1">
      {accountIDs.length === 1 ? (<RampupAccountTable data={filteredData} />) : renderMultipleAccounts(accountIDs)}
    </div>
  );
};

export default AccountsTables;
