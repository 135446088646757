import React, { useState, useRef, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Menu, MenuItem, Icon } from "@blueprintjs/core";
import { Table2, Cell, Column, ColumnHeaderCell } from "@blueprintjs/table";
import { EcpmDataInterface, EcpmSelectMode } from '@/types/ecpm';

interface EcpmByCampTableProps {
  data: EcpmDataInterface[];
  query_mode: EcpmSelectMode;
}

const EcpmByCampTable: React.FC<EcpmByCampTableProps> = ({ data, query_mode }) => {
  const tableRef = useRef<Table2>(null);
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [tableKey, setTableKey] = useState(nanoid());
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  let DISPLAY_COLUMNS = [
    { key: 'date', display: 'Date' },
    { key: 'accountName', display: 'Account name'},
    { key: 'listId', display: 'List id' },
    { key: 'listName', display: 'List Name' },
    { key: 'campId', display: 'Camp id' },
    { key: 'name', display: 'Camp name'},
    { key: 'totalListCount', display: 'Total Lists' },
    { key: 'totalRevenue', display: 'Total revenue' },
    { key: 'ecpm', display: 'eCPM' },
    { key: 'rpc', display: 'RPC' },
    { key: 'totalSentSum', display: 'Sent' },
    { key: 'totalDeliveredSum', display: 'Delivered' },
    { key: 'uniqueOpensSum', display: 'Opens' },
    { key: 'uniqueClicksSum', display: 'Clicks' },
    { key: 'totalClicksSum', display: 'Total Clicks' },
    { key: 'unsubsSum', display: 'Unsubs' },
    { key: 'bouncesSum', display: 'Bounces' },
    { key: 'orRate', display: 'OR' },
    { key: 'crRate', display: 'CR' },
    { key: 'urRate', display: 'UR' },
    { key: 'drRate', display: 'DR' },
    { key: 'complainRate', display: 'Complain' },
    { key: 'bounceRate', display: 'Bounce' },
  ];

  if (query_mode === 'camp_id') DISPLAY_COLUMNS = DISPLAY_COLUMNS.filter( i => !['listId', 'listName'].includes(i.key));
  if (query_mode === 'list') DISPLAY_COLUMNS = DISPLAY_COLUMNS.filter( i => !['campId', 'name', 'date', 'listName', 'accountName'].includes(i.key));
  
  const columnsCount = DISPLAY_COLUMNS.length;
  const [columnWidths, setColumnWidths] = useState<number[]>(new Array(columnsCount).fill(150));

  const formatCellValue = (columnKey: string, value: any) => {
    switch (columnKey) {
      case 'totalRevenue':
      case 'ecpm':
      case 'rpc':
        return `$${Number(value).toFixed(2)}`;
      case 'totalListCount':
        return parseInt(value, 10).toString();
      case 'orRate':
      case 'crRate':
      case 'urRate':
      case 'drRate':
      case 'complainRate':
      case 'bounceRate':
        return `${Number(value).toFixed(2)}%`;
      default:
        return (typeof value === 'number' && value % 1 !== 0 ? Number(value).toFixed(2) : value);
    }
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
    setTableKey(nanoid());
  };

  const sortedData = [...data].sort((a, b) => {
    if (!sortColumn) return 0;

    let valueA = a[sortColumn];
    let valueB = b[sortColumn];

    if (sortColumn === 'total_list_count') {
        valueA = parseInt(valueA, 10);
        valueB = parseInt(valueB, 10);
    }

    if (valueA === valueB) return 0;
    const order = valueA > valueB ? 1 : -1;
    return sortDirection === 'asc' ? order : -order;
});

  const renderColumnHeader = (name: string, columnKey: string) => {
    const menuRenderer = () => (
      <Menu>
        <MenuItem icon="double-chevron-right" onClick={shrinkColumnsToFitContainer} text="Fit to container" />
        <MenuItem icon="double-chevron-down" onClick={() => autoSizeColumn(DISPLAY_COLUMNS.findIndex(col => col.key === columnKey))} text="Auto Size Column" />
        <MenuItem icon="double-chevron-left" onClick={() => autoSizeColumn(null)} text="Auto Size All Columns" />
        <MenuItem icon={sortDirection === 'asc' ? "sort-asc" : "sort-desc"} onClick={() => handleSort(columnKey)} text={`Sort ${sortDirection === 'asc' ? "Descending" : "Ascending"}`} />
      </Menu>
    );
    return (
      <ColumnHeaderCell
        name={name}
        menuRenderer={menuRenderer}
        icon={<Icon icon={sortColumn === columnKey ? (sortDirection === 'asc' ? 'sort-asc' : 'sort-desc') : 'double-caret-vertical'} />}
      />
    );
  };

  const updateColumnsWidth = (newWidths: number[]) => {
    setColumnWidths(newWidths);
    setTableKey(nanoid());
  };

  const autoSizeColumn = (columnIndex: number | null) => {
    const newWidths = [...columnWidths];
    if (columnIndex === null) {
      for (let i = 0; i < columnsCount; i++) {
        newWidths[i] = tableRef.current?.locator?.getWidestVisibleCellInColumn(i) || 150;
      }
    } else {
      newWidths[columnIndex] = tableRef.current?.locator?.getWidestVisibleCellInColumn(columnIndex) || 150;
    }
    updateColumnsWidth(newWidths);
  };

  const shrinkColumnsToFitContainer = () => {
    const containerWidth = tableWrapperRef.current?.offsetWidth || 0;
    const equalWidth = containerWidth / columnsCount;
    const newWidths = new Array(columnsCount).fill(equalWidth);
    updateColumnsWidth(newWidths);
  };

  useEffect(() => {
    shrinkColumnsToFitContainer();
  }, []);

  return (
    <div ref={tableWrapperRef} style={{ height: '410px', overflow: 'auto' }}>
      {sortedData && sortedData[0] && columnWidths.length === DISPLAY_COLUMNS.length && (
        <Table2
          key={tableKey}
          ref={tableRef}
          numRows={sortedData.length}
          columnWidths={columnWidths}
          enableRowHeader={false}
        >
          {DISPLAY_COLUMNS.map((column, index) => (
            <Column
              key={index}
              columnHeaderCellRenderer={() => renderColumnHeader(column.display, column.key)}
              cellRenderer={(rowIndex: number) => {
                const cellData = sortedData[rowIndex][column.key as keyof EcpmDataInterface];
                const formattedData = formatCellValue(column.key, cellData);
                return <Cell>{formattedData !== null ? formattedData : 'N/A'}</Cell>;
              }}
            />
          ))}
        </Table2>
      )}
    </div>
  );
};

export default EcpmByCampTable;
