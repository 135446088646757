import React, { useEffect } from 'react';
import EcpmByCampTable from '@/components/tables/EcpmByCampTable';
import SpinLoader from '../SpinLoader';
import { useEspAccountsStore } from '@/store/esp-accounts-store';
import { useEcpmStore } from '@/store/ecpm-store';
import { useFlashStore } from '@/store/flash-store';

interface SendsCampaignTablesProps {
}

const SendsCampaignTables: React.FC<SendsCampaignTablesProps> = () => {
  const { currentEspAccount } = useEspAccountsStore();
  const {
    loading: ecpmLoading,
    error: ecpmError,
    dataByCampId,
    startDate,
    endDate,
    excludeList,
    recordsThreshold,
    fetchDataByCampId
  } = useEcpmStore();

  const { addMessage } = useFlashStore();

  useEffect(() => {
    fetchDataByCampId(currentEspAccount);
  }, [currentEspAccount, startDate, endDate, excludeList, recordsThreshold]);

  useEffect(() => {
    if (ecpmError) {
      addMessage('error', ecpmError);
    }
  }, [ecpmError, addMessage]);

  return (
    <div className="space-y-1">
      <div className="w-full mb-8">
        {ecpmLoading && (
          <div className="flex min-h-[40px] justify-center">
            <SpinLoader />
          </div>
        )}
        {(!ecpmLoading && dataByCampId.length > 0) && <EcpmByCampTable data={JSON.parse(JSON.stringify(dataByCampId))} query_mode='camp_id' />}
      </div>
    </div>
  );
};

export default SendsCampaignTables;
