import React, {
 useEffect, useRef, useState 
} from 'react';
import { useEspAccountsStore } from '@/store/esp-accounts-store';
import { useStatsStore } from '@/store/stats-store';
import ThresholdInput from '@/components/charts/ContributionGraph/ThresholdInput';
import { ContributionDay } from '@/components/charts/ContributionGraph/ContributionDay';
import { sortStatsData } from '@/utils/SortStatsDataForContributionGrapth';
import FieldsDropdown from '@/components/charts/ContributionGraph/FieldsDropdown';
import { ContributionGraphFilterableFields } from '@/types/contribution-graph-filterable-fields';
import { AccountsNamesColumn } from '@/components/charts/ContributionGraph/grid/AccountsNamesColumn';
import { DatesRow } from '@/components/charts/ContributionGraph/grid/DatesRow';
import { AccountsDataColumn } from '@/components/charts/ContributionGraph/grid/AccountsDataColumn';
import { Label } from '@blueprintjs/core';

const useFieldSelector = () => {
  const [currentField, setCurrentField] = useState<string | null>('CR');
  const fields: ContributionGraphFilterableFields[] = [
    'sent',
    'delivered',
    'opens',
    'clicks',
    'unsubs',
    'offer',
    'adsense',
    'revenue',
    'OR',
    'CR',
    'UR',
    'rpc',
    'cpm',
  ];
  const fieldSelector = (
    <Label className="text-gray-700lll mb-2 block text-xs font-bold uppercase tracking-wide">
      Grid Field
      <FieldsDropdown
        currentField={currentField}
        fields={fields}
        onFieldSelected={setCurrentField}
      />
    </Label>
  );
  return { currentField, fieldSelector };
};

const useGridHorizontalScroller = (deps: any) => {
  const gridRef = useRef(null);
  useEffect(() => {
    gridRef.current
      ?.querySelector('.squares .months:last-child')
      ?.scrollIntoView({
        inline: 'nearest',
        behavior: 'instant',
      });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [deps]);
  return { gridRef };
};

export const ContributionGraph = () => {
  const [threshold, setThreshold] = useState(1000);
  const { espAccounts, currentEspAccount } = useEspAccountsStore();
  const { chartData } = useStatsStore();
  const rowsCount = currentEspAccount ? 1 : espAccounts.length;
  const displayedAccounts = currentEspAccount
    ? [currentEspAccount]
    : espAccounts;
  const chartDataByDate = sortStatsData(chartData, displayedAccounts);
  const { currentField, fieldSelector } = useFieldSelector();
  console.log('chartDataByDate', chartDataByDate);
  const { gridRef } = useGridHorizontalScroller(chartDataByDate);

  // @ts-ignore
  return (
    <>
      <div className="flex gap-x-2">
        {fieldSelector}
        <ThresholdInput
          name="Threshold"
          value={threshold}
          onChange={(e) => setThreshold(e.target.value)}
        />
      </div>

      <div className="flex">
        <div className="graph max-w-full ">
          <AccountsNamesColumn rowsCount={rowsCount} />
        </div>
        <div className="graph max-w-full overflow-x-scroll" ref={gridRef}>
          <DatesRow chartDataByDate={chartDataByDate} />
          <div className="squares flex ">
            {Object.entries(chartDataByDate).map(([_date, accounts], index) => {
              return (
                <AccountsDataColumn
                  key={index}
                  accounts={accounts}
                  currentField={currentField}
                  threshold={threshold}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
