import axios, {
 AxiosError, AxiosHeaders, AxiosHeaderValue 
} from 'axios';
import {
  authPostJsonClient,
  hasHeaders,
} from '@/utils/auth/api-client-instance';
import { authApiUrls } from '@/urls/auth-api-urls';
import {
 err, ok, Result 
} from 'neverthrow';
import { SignInValidationSchema } from '@/components/auth/LoginForm';

const UnhandledErrorMessage = 'Sign In Error: Please contact the support';
type signInSuccessResult = {
  accessToken: string | AxiosHeaderValue;
  refreshToken: string | AxiosHeaderValue;
};
export type signInFailureResult = {
  message: string;
};

function getAxiosErrorMessage(error: AxiosError<any, any>): string {
  const message = error.response?.data?.error;
  return message;
}

export const signIn = async ({
  email,
  password,
}: SignInValidationSchema): Promise<
  Result<signInSuccessResult, signInFailureResult>
> => {
  try {
    const response = await authPostJsonClient(authApiUrls.users.signin, {
      email,
      password,
    });
    const result = response.match(
      (response) => {
        const headers = response.headers;
        if (
          headers instanceof AxiosHeaders &&
          hasHeaders(headers, ['access-token', 'refresh-token'])
        ) {
          const accessToken = headers.get('access-token') || '';
          const refreshToken = headers.get('refresh-token') || '';
          return ok({ accessToken, refreshToken });
        }
        return err({ message: UnhandledErrorMessage });
      },
      (error: Error | AxiosError) => {
        const message: string = axios.isAxiosError(error)
          ? getAxiosErrorMessage(error)
          : error.message;
        return err({ message });
      },
    );
    return result;
  } catch (error) {
    if (error instanceof Error) {
      return err({ message: error.message });
    }
    return err({ message: UnhandledErrorMessage });
  }
};
