import React, { useEffect } from 'react';

import HomeLayout from '@/layouts/HomeLayout';
import DashboardPage from '@/pages/DashboardPage';

import {
 Navigate, Route, Routes, useLocation 
} from 'react-router';
import AccountsContributionPage from '@/pages/AccountsContributionsPage';
import { useAuthStore } from '@/store/auth/auth-store';
import { Outlet } from 'react-router-dom';
import { fetchMe } from '@/utils/users/requests/fetch-me-request';
import LoggedOutLayout from '@/layouts/LoggedOutLayout';
import { SignUpPage } from '@/pages/auth/SignUpPage';
import { LoginPage } from '@/pages/auth/LoginPage';
import { ApprovalStatusPage } from '@/pages/auth/ApprovalStatusPage';
import UnderReviewLayout from '@/layouts/UnderReviewLayout';

const ProtectedRoute: React.FC = () => {
  const { accessToken } = useAuthStore.getState();

  if (!accessToken) {
    return <Navigate to="/login" replace />;
  }
  return <Outlet />;
};
const ApprovalRequiredRoute: React.FC = () => {
  const { accessToken, currentUser } = useAuthStore.getState();
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  const { approval_status } = currentUser;

  if (accessToken && !approval_status) {
    return <Navigate to="/approval-status" replace />;
  }
  return <Outlet />;
};
const NoUserRoute: React.FC = () => {
  const { accessToken, currentUser } = useAuthStore.getState();

  if (accessToken && currentUser) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

const AppRouter = () => {
  const { setCurrentUser, setAccessToken, setRefreshToken } = useAuthStore();
  const location = useLocation();
  useEffect(() => {
    fetchMe().then((result) => {
      if (result.isErr()) {
        setAccessToken(null);
        setRefreshToken(null);
        setCurrentUser(null);
        // return <Navigate to="/login" replace/>
      }
      if (result.isOk() && result.value.user) {
        setCurrentUser(result.value.user);
      }
    });
  }, [location]);

  return (
    <Routes>
      <Route element={<NoUserRoute />}>
        <Route path="/sign-up" element={<LoggedOutLayout />}>
          <Route index element={<SignUpPage />} />
        </Route>

        <Route path="/login" element={<LoggedOutLayout />}>
          <Route index element={<LoginPage />} />
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="/approval-status" element={<UnderReviewLayout />}>
          <Route index element={<ApprovalStatusPage />} />
        </Route>
      </Route>
      <Route element={<ApprovalRequiredRoute />}>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<DashboardPage />} />
            <Route
              path="contributions/"
              element={<AccountsContributionPage />}
            />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />}></Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;
