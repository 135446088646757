import { IEspProvider } from '@/types/esp-provider';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { useAuthStore } from '@/store/auth/auth-store';
import { apiV1Providers } from '@/api';

type EspProvidersState = {
  espProviders: IEspProvider[];
  error: string | null;
  currentEspProvider: IEspProvider | null;
};
type EspProvidersActions = {
  fetchEspProviders: () => void;
  setCurrentEspProvider: (espProvider: IEspProvider | null) => void;
};

export const useEspProvidersStore = create<
  EspProvidersState & EspProvidersActions,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer<EspProvidersState & EspProvidersActions>((set) => ({
      espProviders: [],
      error: null,
      currentEspProvider: null,
      setCurrentEspProvider: (espProvider: IEspProvider | null) => {
        set((state) => {
          state.currentEspProvider = espProvider;
        });
      },
      fetchEspProviders: async () => {
        try {
          const { accessToken } = useAuthStore.getState();
          const response = await apiV1Providers.list({headers: { Authorization: 'Bearer ' + accessToken },});
          set((state) => {
            state.espProviders = response;
            state.error = null;
          });
        } catch (err: unknown) {
          set((state) => {
            state.espProviders = [];
            if (err instanceof Error) {
              state.error = `Failed to fetch esp providers: ${err?.message}`;
            } else {
              state.error = `Failed to fetch esp providers`;
            }
          });
        }
      },
    })),
  ),
);
