import { useDashboardViewStore } from '@/store/dashboard-view-store';

const CtaSwitch = () => {
  const {currentCtaView, switchCtaView} = useDashboardViewStore();

  return (
    <div className="flex justify-end items-end mb-px">
        <div className="text-gray-400 border-transparent inline-flex items-center h-[30px] -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap">
          CTA Type:
        </div>
        <button 
          onClick={() => switchCtaView('offer')} 
          className={`${currentCtaView === 'offer' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'} inline-flex items-center h-[30px] px-4 -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap cursor-pointer focus:outline-none`}>
            Offer
        </button>

        <button
          onClick={() => switchCtaView('adsense')} 
          className={`${currentCtaView === 'adsense' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'} inline-flex items-center h-[30px] px-4 -mb-px text-xs text-center bg-transparent border-b-2 whitespace-nowrap cursor-pointer focus:outline-none`}>
            Article
        </button>
    </div>
  )
}

export default CtaSwitch;
