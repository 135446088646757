import React, { useEffect } from 'react';
import RateChart from '@/components/charts/RateChart';
import { useStatsStore } from '@/store/stats-store';
import { useEspAccountsStore } from '@/store/esp-accounts-store';


const RateCharts: React.FC = () => {

  const {
    loading,
    chartData,
    startDate,
    endDate,
    fetchStatsData,
    fetchStatsForAccount
  } = useStatsStore();
  const { currentEspAccount } = useEspAccountsStore();

  useEffect(() => {
    fetchStatsData();
  }, [fetchStatsData]);

  useEffect(() => {
    if (currentEspAccount) {
      fetchStatsForAccount(currentEspAccount);
    } else {
      fetchStatsData();
    }
  }, [currentEspAccount, startDate, endDate]);

  return (
    <>
      {!loading && chartData.length > 0 && (
        <>
          <div className="w-full px-2">
            <RateChart
              data={JSON.parse(JSON.stringify(chartData))}
              rateField="OR"
              yAxisTitle="Opens rate"
            />
          </div>
          <div className="w-full px-2">
            <RateChart
              data={JSON.parse(JSON.stringify(chartData))}
              rateField="CR"
              yAxisTitle="Click rate"
            />
          </div>
          <div className="w-full px-2">
            <RateChart
              data={JSON.parse(JSON.stringify(chartData))}
              rateField="SR"
              yAxisTitle="Spam rate"
            />
          </div>
          <div className="w-full px-2">
            <RateChart
              data={JSON.parse(JSON.stringify(chartData))}
              rateField="UR"
              yAxisTitle="Unsubscribe rate"
            />
          </div>
        </>
      )}
    </>
  );
};

export default RateCharts;
