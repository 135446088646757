import React, { useEffect } from 'react';

import DashboardHeadingPanel from '@/components/Dashboard/DashboardHeadingPanel';
import SpinLoader from '@/components/SpinLoader';
import { useStatsStore } from '@/store/stats-store';
import { useFlashStore } from '@/store/flash-store';
import { useEspAccountsStore } from '@/store/esp-accounts-store';
import { ContributionGraph } from '@/components/charts/ContributionGraph/ContributionGraph';

const AccountsContributionPage: React.FC = () => {
  const {
    loading,
    error,
    startDate,
    endDate,
    fetchStatsData,
    fetchStatsForAccount,
  } = useStatsStore();
  const { addMessage } = useFlashStore();
  const { currentEspAccount } = useEspAccountsStore();
  useEffect(() => {
    fetchStatsData();
  }, [fetchStatsData]);

  useEffect(() => {
    if (error) {
      addMessage('error', error);
    }
  }, [error, addMessage]);
  useEffect(() => {
    if (currentEspAccount) {
      fetchStatsForAccount(currentEspAccount);
    } else {
      fetchStatsData();
    }
  }, [currentEspAccount, startDate, endDate]);

  return (
    <div className="container mx-auto w-full px-4">
      <h1 className="py-2 text-lg">Accounts Contributions</h1>
      <DashboardHeadingPanel/>
      <ContributionGraph />
      <div className="flex min-h-[40px] justify-center">
        {loading && <SpinLoader />}
      </div>
    </div>
  );
};

export default AccountsContributionPage;
