import React, { useCallback, useEffect } from 'react';

import { Label } from '@blueprintjs/core';
import { DateRangeInput3 } from '@blueprintjs/datetime2';
import { useStatsStore } from '@/store/stats-store';
import { useRampupStore } from '@/store/rampup-store';
import { useCtaRevenueStore } from '@/store/cta-revenue-store';
import { useEcpmStore } from '@/store/ecpm-store';
import { format, parse, isValid } from 'date-fns';


import '@blueprintjs/datetime2/lib/css/blueprint-datetime2.css'

export default function DateRangeSelector() {
  const { startDate, endDate, setStartDate, setEndDate } = useStatsStore();
  const { setStartDate: setRampupStartDate, setEndDate: setRampupEndDate } = useRampupStore();
  const { setStartDate: setEcpmStartDate, setEndDate: setEcpmEndDate } = useEcpmStore();
  const { setStartDate: setCtaStartDate, setEndDate: setCtaEndDate } = useCtaRevenueStore();

  const convertStringToDate = (dateStr: string | null): Date | null => {
    if (dateStr === null) return null;
    const parsedDate = parse(dateStr, dateFormat, new Date());
    return isValid(parsedDate) ? parsedDate : null;
  };

  const handleDateRangeChange = useCallback((range: [Date | null, Date | null]) => {
    const [start, end] = range;
    if (start) setStartDate(format(start, dateFormat));
    if (end) setEndDate(format(end, dateFormat));
  }, [setStartDate, setEndDate]);

  useEffect(() => {
    if (startDate) {
      setRampupStartDate(startDate);
      setEcpmStartDate(startDate);
      setCtaStartDate(startDate);
    }
  }, [startDate, setRampupStartDate, setEcpmStartDate]);

  useEffect(() => {
    if (endDate) {
      setRampupEndDate(endDate);
      setEcpmEndDate(endDate);
      setCtaEndDate(endDate)
    }
  }, [endDate, setRampupEndDate, setEcpmEndDate]);

  const dateFormat = 'yyyy-MM-dd';
  const formatDate = useCallback((date: Date | null) => {
    return date ? format(date, dateFormat) : '';
  }, [dateFormat]);

  const parseDate = useCallback(
    (str: string) => {
      const parsedDate = parse(str, dateFormat, new Date());
      return isValid(parsedDate) ? parsedDate : null;
    },
    [dateFormat],
  );

  return (
    <div className="flex">
      <div className="px-2">
        <Label>
          <div className="w-full flex items-center justify-between ">
            <div className='w-[48%]'>Stat date</div>
            <div className='w-[48%]'>End date</div>
          </div>

          <DateRangeInput3
            formatDate={formatDate}
            onChange={handleDateRangeChange}
            parseDate={parseDate}
            placeholder="MM/DD/YYYY"
            shortcuts={true}
            value={[convertStringToDate(startDate), convertStringToDate(endDate)]}
          />
        </Label>
      </div>
    </div>
  );
}
