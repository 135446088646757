import { create } from "zustand";

import { Message } from '@/types/flash';


type FlashState = {
  messages: Message[];
  addMessage: (type: 'success' | 'error', message: string) => void;
  removeMessage: (id: string) => void;
};

export const useFlashStore = create<FlashState>((set) => ({
  messages: [],
  addMessage: (type, message) =>
    set((state) => ({
      ...state,
      messages: [...state.messages, { id: `${Date.now()}`, type, content: message }],
    })),
  removeMessage: (id) =>
    set((state) => ({
      ...state,
      messages: state.messages.filter((message) => message.id !== id),
    })),
}));
