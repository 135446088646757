import React, { useState, useRef, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Icon, Menu, MenuItem } from "@blueprintjs/core";
import { Table2, Cell, Column, ColumnHeaderCell } from "@blueprintjs/table";
import { RampupDataInterface } from '@/types/rampup';
import { useRampupStore } from '@/store/rampup-store';

interface RampupAccountTableProps {
    data: RampupDataInterface[];
}

const DISPLAY_COLUMNS: Record<string, keyof RampupDataInterface> = {
    'Date': 'date',
    'New Records': 'newRecordsSum',
    'New Subs': 'newSubsSum',
    'Opens': 'newSubsOpenSum',
    'Clicks': 'newSubsClicksSum',
    'Unsubs': 'newSubsUnsubSum',
    'New Sub_OR': 'newSubsOr',
    'New Sub_CR': 'newSubsCr',
    'D UR': 'newSubsUr',
    'Total Subs': 'totalSubsSum',
    'Total Opens': 'totalOpensSum',
    'Total Clicks': 'totalClicksSum',
    'Total Unsubs': 'totalUnsubsSum',
    'T OR': 'totalOr',
    'T CR': 'totalCr',
    'T UR': 'newSubsUr',
    'Delt Subs': 'totalSubsSumDelta',
    'Delt Opens': 'totalOpensSumDelta',
    'Delt Clicks': 'totalClicksSumDelta',
    'Del OR': 'deltaOr'
};

export const RampupAccountTable: React.FC<RampupAccountTableProps> = ({ data }) => {
    const { threshold } = useRampupStore()
    const tableRef = useRef<Table2>(null);
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const [tableKey, setTableKey] = useState(nanoid());
    const columnsCount = Object.keys(DISPLAY_COLUMNS).length;
    const [columnWidths, setColumnWidths] = useState<number[]>(new Array(columnsCount).fill(150));

    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const handleSort = (column: keyof RampupDataInterface) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
        setTableKey(nanoid());
    };

    const sortedData = [...data].sort((a, b) => {
        if (!sortColumn) return 0;
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];
        if (valueA === valueB) return 0;
        const order = valueA > valueB ? 1 : -1;
        return sortDirection === 'asc' ? order : -order;
    });

    const shouldHighlightCell = (value: any, key: string): boolean => {
        return (key.includes("Ur") || key.includes("Or") || key.includes("Cr")) && typeof value === "number" && value < threshold;
    };

    const updateColumnsWidth = (newWidths: number[]) => {
        setColumnWidths(newWidths);
        setTableKey(nanoid());
    };

    const autoSizeColumn = (columnIndex: number | null) => {
        let newWidths = [...columnWidths];
        if (columnIndex === null) {
            newWidths = newWidths.map((_width, index) => tableRef.current?.locator?.getWidestVisibleCellInColumn(index) || 150);
        } else {
            newWidths[columnIndex] = tableRef.current?.locator?.getWidestVisibleCellInColumn(columnIndex) || 150;
        }
        updateColumnsWidth(newWidths);
    };

    const shrinkColumnsToFitContainer = () => {
        const containerWidth = tableWrapperRef.current?.offsetWidth || 0;
        const equalWidth = containerWidth / columnsCount;
        const newWidths = new Array(columnsCount).fill(equalWidth);
        updateColumnsWidth(newWidths);
    };

    useEffect(() => {
        shrinkColumnsToFitContainer();
    }, []);

    const renderColumnHeader = (name: string, columnKey: keyof RampupDataInterface) => {
        const menuRenderer = () => (
            <Menu>
                <MenuItem icon="double-chevron-right" onClick={shrinkColumnsToFitContainer} text="Fit to container" />
                <MenuItem icon="double-chevron-down" onClick={() => autoSizeColumn(Object.values(DISPLAY_COLUMNS).indexOf(columnKey))} text="Auto Size Column" />
                <MenuItem icon="double-chevron-left" onClick={() => autoSizeColumn(null)} text="Auto Size All Columns" />
                <MenuItem icon={sortDirection === 'asc' ? "sort-asc" : "sort-desc"} onClick={() => handleSort(columnKey)} text={`Sort ${sortDirection === 'asc' ? "Descending" : "Ascending"}`} />
            </Menu>
        );
        return (
            <ColumnHeaderCell className="font-bold" name={name} menuRenderer={menuRenderer} 
                icon={<Icon icon={sortColumn === columnKey ? (sortDirection === 'asc' ? 'sort-asc' : 'sort-desc') : 'double-caret-vertical'} />}
            />
        );
    };

    const columns = Object.entries(DISPLAY_COLUMNS).map(([header, key], index) => (
        <Column
            key={index}
            columnHeaderCellRenderer={() => renderColumnHeader(header, key)}
            cellRenderer={(rowIndex: number) => {
                const shouldAppendPercentage = ["New Sub_OR", "New Sub_CR", "D UR", "T OR", "T CR", "Del OR"].includes(header);
                const cellData = sortedData[rowIndex][key];
                const cellValue = cellData + (shouldAppendPercentage ? '%' : '');
                const highlight = shouldHighlightCell(cellData, key);
                return <Cell className={highlight ? 'bg-red-500 text-white' : ''}>{cellData !== null ? cellValue : 'N/A'}</Cell>;
            }}
        />
    ));

    return (
        <div ref={tableWrapperRef} className={'border-gray-100 overflow-auto max-h-[420px]'}>
            <Table2
                key={tableKey}
                ref={tableRef}
                numRows={sortedData.length}
                columnWidths={columnWidths}
                enableRowHeader={false}
            >
                {columns}
            </Table2>
        </div>
    );
};

export default RampupAccountTable;
