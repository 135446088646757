import React, { useEffect } from 'react';
import SpinLoader from '@/components/SpinLoader';
import SendsOverviewSummaryTable from "@/components/tables/SendsOverviewSummaryTable"
import { useStatsStore } from '@/store/stats-store';
import { useEspAccountsStore } from '@/store/esp-accounts-store';

const SendsOverviewTable: React.FC = () => {
  const {
    loading,
    statsData,
    startDate,
    endDate,
    fetchOverviewStatsData
  } = useStatsStore();
  const { currentEspAccount } = useEspAccountsStore();
  useEffect(() => {
    fetchOverviewStatsData();
  }, [fetchOverviewStatsData]);
  useEffect(() => {
    if (currentEspAccount) {
      fetchOverviewStatsData(currentEspAccount);
    } else {
      fetchOverviewStatsData();
    }
  }, [currentEspAccount, startDate, endDate]);
  const TableComponent = SendsOverviewSummaryTable;
  return (
    <div className="flex w-full flex-wrap">
      {loading && (
        <div className="flex w-full min-h-[160px] items-center justify-center">
          <SpinLoader />
        </div>
      )}
      {!loading && statsData.length > 0 && (
        <>
          <div className="w-full mb-8">
            <TableComponent
              data={JSON.parse(JSON.stringify(statsData))}
            />
          </div>
        </>
       )}
    </div>
  );
};

export default SendsOverviewTable;
