import axios, {
 AxiosError, AxiosHeaders, AxiosHeaderValue 
} from 'axios';
import {
  authPostJsonClient,
  hasHeaders,
} from '@/utils/auth/api-client-instance';
import { authApiUrls } from '@/urls/auth-api-urls';
import {
 err, ok, Result 
} from 'neverthrow';
import { SignUpValidationSchema } from '@/components/auth/SignUpForm';

const UnhandledErrorMessage = 'Sign Up Error: Please contact the support';

function getAxiosErrorMessage(error: AxiosError<any, any>): string {
  const message = error.response?.data?.error;
  return message;
}

type signUpSuccessResult = {
  accessToken: string | AxiosHeaderValue;
  refreshToken: string | AxiosHeaderValue;
};
type signUpFailureResult = {
  type?: string;
  message: string;
};
export const signUp = async ({
  email,
  password,
  passwordConfirmation,
}: SignUpValidationSchema): Promise<
  Result<signUpSuccessResult, signUpFailureResult>
> => {
  try {
    const response = await authPostJsonClient(authApiUrls.users.signup, {
      email,
      password,
      password_confirmation: passwordConfirmation,
    });

    const result = response.match(
      (response) => {
        const headers = response.headers;
        if (
          headers instanceof AxiosHeaders &&
          hasHeaders(headers, ['access-token', 'refresh-token'])
        ) {
          const accessToken = headers.get('access-token');
          const refreshToken = headers.get('refresh-token');
          return ok({
            accessToken,
            refreshToken,
          });
        }
        return err({ message: UnhandledErrorMessage });
      },
      (error: Error | AxiosError) => {
        const message: string = axios.isAxiosError(error)
          ? getAxiosErrorMessage(error)
          : error.message;
        return err({ message });
      },
    );
    return result;
  } catch (error) {
    console.log('sign up error:', error);
    if (error instanceof Error) {
      return err({ message: error.message });
    }
    return err({ message: UnhandledErrorMessage });
  }
};
