import React, { useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import TextHookFormInput from '@/components/shared/inputs/TextHookFormInput';
import { FilledButton } from '@/components/shared/buttons/FilledButton';
import { useAuthStore } from '@/store/auth/auth-store';
import { signUp } from '@/utils/auth/requests/sign-up-request';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';

const validationSchema = z
  .object({
    email: z
      .string()
      .min(1, { message: 'Email is required' })
      .email({ message: 'Must be a valid email' }),
    password: z
      .string()
      .min(6, { message: 'Password must be atleast 6 characters' }),
    passwordConfirmation: z
      .string()
      .min(1, { message: 'Confirm Password is required' }),
    agree: z.boolean().optional(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    path: ['passwordConfirmation'],
    message: "Passwords don't match",
  });

export type SignUpValidationSchema = z.infer<typeof validationSchema>;

export function SignUpForm() {
  const navigate = useNavigate();
  const { setRefreshToken, setAccessToken } = useAuthStore();
  const {
    register,
    watch,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpValidationSchema>({resolver: zodResolver(validationSchema),});
  const onSubmit: SubmitHandler<SignUpValidationSchema> = async (data) => {
    const signUpResult = await signUp(data);
    signUpResult.match(
      ({ accessToken, refreshToken }) => {
        setAccessToken(accessToken as string);
        setRefreshToken(refreshToken as string);
        setTimeout(() => {
          navigate('/');
        }, 0);
      },
      (error) => {
        setError('root.serverError', { type: '422', message: error.message });
      },
    );
  };
  const password = useRef({});
  password.current = watch('password', '');
  const isServerErrorPresent = Boolean(errors?.root?.serverError);
  return (
    <form
      onSubmit={handleSubmit(
        (data) => {
          console.log('errors', errors);
          onSubmit(data);
        },
        (errors) => {
          console.log('sign up - errors', errors);
        },
      )}
      className="flex flex-col gap-[20px]"
    >
      {isServerErrorPresent && (
        <div className="text-red-700">{errors?.root?.serverError?.message}</div>
      )}
      <TextHookFormInput
        error={isServerErrorPresent || errors.email || false}
        placeholder="Email"
        register={register('email', { required: true })}
      />

      <TextHookFormInput
        error={isServerErrorPresent || errors.password || false}
        type="password"
        placeholder="Password"
        register={register('password', { required: true })}
      />

      <TextHookFormInput
        error={isServerErrorPresent || errors.passwordConfirmation || false}
        type="password"
        placeholder="Confirm Password"
        register={register('passwordConfirmation', {
          required: true,
          validate: (val) =>
            val === password.current || 'The password does not match',
        })}
      />

      <FilledButton
        additionalClasses="w-full"
        content={<span className="text-white">Sign Up</span>}
      />
    </form>
  );
}
