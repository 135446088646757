import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";

type DrawerState = {
  isOpen: boolean;
  isSettingsOpen: boolean;
}

type DrawerActions = {
  toggle: () => void;
  toggleSettings: () => void;
}

export const useDrawerStore = create<DrawerActions & DrawerState, [['zustand/devtools', never], ['zustand/immer', never]]>(
  devtools(immer<DrawerActions & DrawerState>((set) => ({
    isOpen: false,
    isSettingsOpen: false,
    toggle: () =>
      set((state) => {
        state.isOpen = !state.isOpen;
      }),
    toggleSettings: () =>
      set((state) => {
        state.isSettingsOpen = !state.isSettingsOpen;
      }),
  }))));
