import React from 'react';
import BaseChart from './BaseChart';
import { TopLevelSpec as VlSpec } from 'vega-lite';
import { StatsDataInterface } from '@/types/stats';

export interface YField {
  field: string;
  title: string;
  format?: string;
  type: 'quantitative' | 'ordinal' | 'temporal' | 'nominal';
  as?: string;
}

interface DoubleAxisTooltipProps {
  data: StatsDataInterface[];
  yFields: YField[];
  twclasses?: string;
}

const DoubleAxisTooltip: React.FC<DoubleAxisTooltipProps> = ({ data, yFields, twclasses = '' }) => {
  const spec: VlSpec = {
    $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
    data: { values: data },
    transform: [
      {
        calculate: "datetime(datum.date)",
        as: "date"
      },
      {
        calculate: "datum.unsubs == 0 ? 0 : format(datum.clicks / datum.unsubs, '.4f')",
        as: "CUR"
      },
      {
        aggregate: yFields.map(field => ({ op: "mean", field: field.field, as: field.as || field.field })),
        groupby: ["date"]
      }
    ],
    resolve: {
      scale: {
        y: "independent"
      }
    },
    layer: [
      {
        mark: { type: 'rule', tooltip: { content: 'data' } },
        encoding: {
          x: {
            field: 'date',
            type: 'temporal',
            axis: {
              titleFontSize: 0,
              labelFontSize: 8,
              format: "%m/%d/%Y",
              titleOpacity: 0,
              labelOpacity: 0
            }
          },
          y: {
            field: yFields[0].as || yFields[0].field,
            type: 'quantitative',
            axis: {
              title: yFields[0].title,
              titleColor: "#f58419",
              titleFontSize: 8,
              format: yFields[0].format || ".2f",
              titleOpacity: 0,
              labelOpacity: 0
            }
          },
          opacity: {
            condition: { value: 1, param: 'hover', empty: false },
            value: 0,
          },
          tooltip: [
            { field: "date", type: "temporal", title: "Date" },
            ...yFields.map(field => ({
              field: field.as || field.field,
              type: field.type,
              title: field.title,
              format: field.format || ".2f"
            }))
          ]
        },
        params: [
          {
            name: 'hover',
            select: {
              type: 'point',
              fields: ['date'],
              nearest: true,
              on: 'mouseover',
              clear: 'mouseout',
            },
          },
        ],
      }
    ],
  };

  return (
    <BaseChart spec={spec} actions={false} twclasses={"opacity-0 hover:opacity-20 " + twclasses} />
  );
};

export default DoubleAxisTooltip;
