import "@/entrypoints/main.css"
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from '@/App';

// import theme from './theme';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
