import React from 'react';
import { Outlet } from 'react-router-dom';
import 'react-modern-drawer/dist/index.css';
import LoggedOutHeader from '@/components/logged-out-header/LoggedOutHeader';

const LoggedOutLayout = () => {
  return (
    <>
      <LoggedOutHeader />
      <Outlet />
    </>
  );
};

export default LoggedOutLayout;
