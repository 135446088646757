import { Button } from '@blueprintjs/core';
import { useDrawerStore } from '@/store/drawer-store';


function ToggleSettingsButton() {

  const { toggleSettings, isSettingsOpen } = useDrawerStore();

  return (
    <Button
      icon="cog"
      minimal={ true }
      active={isSettingsOpen}
      onClick={toggleSettings}
    />
  );
}

export default ToggleSettingsButton;