// ecpm-store.ts
import axios from 'axios';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { EcpmDataInterface } from '@/types/ecpm';
import { IEspAccount } from '@/types/esp-account';
import { useAuthStore } from '@/store/auth/auth-store';
import { apiV1Ecpm } from '@/api';

type EcpmState = {
  loading: boolean;
  isChartLoading: boolean;
  ecpmChartData: EcpmDataInterface[];
  dataByList: EcpmDataInterface[];
  dataByCampId: EcpmDataInterface[];
  excludeList: string[];
  recordsThreshold: number;
  error: string | null;
  startDate: string | null;
  endDate: string | null;
};

type EcpmActions = {
  fetchDataByList: (account: IEspAccount | null) => void;
  fetchDataByCampId: (account: IEspAccount | null) => void;
  fetchChartByList: (account: IEspAccount | null) => void;
  setTotalListThreshold: (val: number) => void;
  setExcludeList: (val: string[]) => void;
  setStartDate: (date: string | null) => void;
  setEndDate: (date: string | null) => void;
};

export const useEcpmStore = create<
  EcpmState & EcpmActions,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer<EcpmState & EcpmActions>((set, getState) => ({
      loading: false,
      isChartLoading: false,
      ecpmChartData: [],
      recordsThreshold: 0,
      dataByList: [],
      dataByCampId: [],
      error: null,
      startDate: null,
      endDate: null,
      excludeList: [],
      setStartDate: (date) =>
        set((state) => {
          state.startDate = date;
        }),
      setEndDate: (date) =>
        set((state) => {
          state.endDate = date;
        }),
      setExcludeList: (val) =>
        set((state) => {
          state.excludeList = val;
        }),
      setTotalListThreshold: (val) =>
        set((state) => {
          state.recordsThreshold = val;
        }),
      fetchDataByList: async (account: IEspAccount | null) => {
        try {
          set((state) => {
            state.loading = true;
          });
          const queryParams = {
            start_date: getState().startDate,
            end_date: getState().endDate,
            select_mode: 'list',
            account_id: account ? account.id : null,
            exclude_list: getState().excludeList.join(','),
            records_threshold: getState().recordsThreshold,
          };
          const { accessToken } = useAuthStore.getState();
          const response = await apiV1Ecpm.list({
            query: queryParams,
            headers: { Authorization: 'Bearer ' + accessToken },
          });

          const ecpmData = response.map((item: EcpmDataInterface) => ({...item,}));
          set((state) => {
            state.loading = false;
            state.dataByList = ecpmData;
            state.error = null;
          });
        } catch (err: unknown) {
          console.log('err', err);
          set((state) => {
            state.dataByList = [];
            state.loading = false;
            if (err instanceof Error) {
              state.error = `Failed to fetch ecpm data: ${err.message}`;
            } else {
              state.error = `Failed to fetch ecpm data`;
            }
          });
        }
      },
      fetchChartByList: async (account: IEspAccount | null) => {
        try {
          set((state) => {
            state.isChartLoading = true;
          });
          const queryParams = {
            start_date: getState().startDate,
            end_date: getState().endDate,
            select_mode: 'list',
            account_id: account ? account.id : null,
            exclude_list: getState().excludeList.join(','),
            records_threshold: getState().recordsThreshold,
          };
          const { accessToken } = useAuthStore.getState();
          const response = await apiV1Ecpm.chart({
            query: queryParams,
            headers: { Authorization: 'Bearer ' + accessToken },
          });

          const ecpmData = response.map((item: EcpmDataInterface) => ({...item,}));
          set((state) => {
            state.isChartLoading = false;
            state.ecpmChartData = ecpmData;
            state.error = null;
          });
        } catch (err: unknown) {
          console.log('err', err);
          set((state) => {
            state.ecpmChartData = [];
            state.isChartLoading = false;
            if (err instanceof Error) {
              state.error = `Failed to fetch ecpm data: ${err.message}`;
            } else {
              state.error = `Failed to fetch ecpm data`;
            }
          });
        }
      },
      fetchDataByCampId: async (account: IEspAccount | null) => {
        try {
          set((state) => {
            state.loading = true;
          });
          const queryParams = {
            start_date: getState().startDate,
            end_date: getState().endDate,
            select_mode: 'camp_id',
            account_id: account ? account.id : null,
            exclude_list: getState().excludeList.join(','),
            records_threshold: getState().recordsThreshold,
          };
          const { accessToken } = useAuthStore.getState();
          const response = await apiV1Ecpm.list({
            query: queryParams,
            headers: { Authorization: 'Bearer ' + accessToken },
          });
          console.log('RESP', response);
          const ecpmData = response.map((item: EcpmDataInterface) => ({...item,}));
          set((state) => {
            state.loading = false;
            state.dataByCampId = ecpmData;
            state.error = null;
          });
        } catch (err: unknown) {
          console.log('err', err);
          set((state) => {
            state.dataByCampId = [];
            state.loading = false;
            if (err instanceof Error) {
              state.error = `Failed to fetch ecpm data: ${err.message}`;
            } else {
              state.error = `Failed to fetch ecpm data`;
            }
          });
        }
      },
    })),
  ),
);
