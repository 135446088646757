import React, { useEffect } from 'react';
import CtaTable from '@/components/tables/CtaTable';
import SpinLoader from '../SpinLoader';
import { useEspAccountsStore } from '@/store/esp-accounts-store';
import { useCtaRevenueStore } from '@/store/cta-revenue-store';
import { useFlashStore } from '@/store/flash-store';
import { useDashboardViewStore } from '@/store/dashboard-view-store';


interface CtaTablesProps {}

const CtaTables: React.FC<CtaTablesProps> = () => {
  const { currentEspAccount } = useEspAccountsStore();
  const {
    loading: ctaLoading,
    error: ctaError,
    ctaRevenueData,
    fetchData,
    startDate,
    endDate,
  } = useCtaRevenueStore();

  const { currentCtaView } = useDashboardViewStore();

  const { addMessage } = useFlashStore();

  useEffect(() => {
    fetchData(currentEspAccount, currentCtaView);
  }, [currentEspAccount, startDate, endDate, currentCtaView]);

  useEffect(() => {
    if (ctaError) {
      addMessage('error', ctaError);
    }
  }, [ctaError, addMessage]);

  return (
    <div className="space-y-1">
      <div className="w-full mb-8">
        {ctaLoading && (
          <div className="flex min-h-[40px] justify-center">
            <SpinLoader />
          </div>
        )}
        {(!ctaLoading && ctaRevenueData.length > 0) && <CtaTable data={JSON.parse(JSON.stringify(ctaRevenueData))} query_mode={currentCtaView} />}
      </div>
    </div>
  );
};

export default CtaTables;
