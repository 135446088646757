import jwtDecode from 'jwt-decode';
import {z} from 'zod';


const TokenDataSchema = z.object({
  user_id: z.string(),
  exp: z.number(),
  iat: z.number(),
});

export const isExpired = (exp: number | undefined): boolean => {
  if (!exp) return true;
  const now = Math.floor(Date.now() / 1000);
  return exp < now;
};

export type TokenData = z.infer<typeof TokenDataSchema>;
export const decodeAccessToken = (accessToken: string) => {
  return TokenDataSchema.parse(jwtDecode<TokenData>(accessToken));
};
