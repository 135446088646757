import React, { useState, useRef, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Menu, MenuItem, Icon } from "@blueprintjs/core";
import { Table2, Cell, Column, ColumnHeaderCell } from "@blueprintjs/table";
import { CtaRevenueDataInterface } from '@/types/cta-revenue';

interface CtaTableProps {
  data: CtaRevenueDataInterface[];
  query_mode: string;
}

const CtaTable: React.FC<CtaTableProps> = ({ data, query_mode }) => {
  console.log('CTA TABLE DATA', data)
  const tableRef = useRef<Table2>(null);
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [tableKey, setTableKey] = useState(nanoid());
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const DISPLAY_COLUMNS: Array<{ key: keyof CtaRevenueDataInterface; display: string }> = [
    { key: 'offerUid', display: 'Offer UID' },
    { key: 'baseUrl', display: 'Base URL' },
    { key: 'totalRevenue', display: 'Total Revenue' },
    { key: 'totalSent', display: 'Total Sent' },
    { key: 'totalOpens', display: 'Total Opens' },
    { key: 'totalClicks', display: 'Total Clicks' },
    { key: 'totalUnsubs', display: 'Total Unsubs' },
    { key: 'cr', display: 'CR' },
    { key: 'or', display: 'OR' },
    { key: 'epc', display: 'EPC' },
    { key: 'ecpm', display: 'eCPM' },
    { key: 'frequency', display: 'Frequency' },
    { key: 'lastSent', display: 'Last Sent' },
  ];

  const columnsCount = DISPLAY_COLUMNS.length;
  const [columnWidths, setColumnWidths] = useState<number[]>(new Array(columnsCount).fill(150));

  const formatCellValue = (columnKey: string, value: any) => {
    switch (columnKey) {
      case 'totalRevenue':
      case 'ecpm':
      case 'epc':
        return `$${Number(value).toFixed(2)}`;
      case 'cr':
      case 'or':
        return `${Number(value).toFixed(2)}%`;
      case 'frequency':
      case 'totalSent':
      case 'totalOpens':
      case 'totalClicks':
      case 'totalUnsubs':
      case 'lastSent':
        return value;
      default:
        return value;
    }
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
    setTableKey(nanoid());
  };

  const sortedData = [...data].sort((a, b) => {
    if (!sortColumn) return 0;

    let valueA = a[sortColumn];
    let valueB = b[sortColumn];

    if (['total_revenue', 'ecpm', 'epc', 'cr'].includes(sortColumn)) {
        valueA = parseFloat(valueA);
        valueB = parseFloat(valueB);
    }

    if (valueA === valueB) return 0;
    const order = valueA > valueB ? 1 : -1;
    return sortDirection === 'asc' ? order : -order;
  });

  const renderColumnHeader = (name: string, columnKey: string) => {
    const menuRenderer = () => (
      <Menu>
        <MenuItem icon="double-chevron-right" onClick={shrinkColumnsToFitContainer} text="Fit to container" />
        <MenuItem icon="double-chevron-down" onClick={() => autoSizeColumn(DISPLAY_COLUMNS.findIndex(col => col.key === columnKey))} text="Auto Size Column" />
        <MenuItem icon="double-chevron-left" onClick={() => autoSizeColumn(null)} text="Auto Size All Columns" />
        <MenuItem icon={sortDirection === 'asc' ? "sort-asc" : "sort-desc"} onClick={() => handleSort(columnKey)} text={`Sort ${sortDirection === 'asc' ? "Descending" : "Ascending"}`} />
      </Menu>
    );
    return (
      <ColumnHeaderCell
        name={name}
        menuRenderer={menuRenderer}
        icon={<Icon icon={sortColumn === columnKey ? (sortDirection === 'asc' ? 'sort-asc' : 'sort-desc') : 'double-caret-vertical'} />}
      />
    );
  };

  const updateColumnsWidth = (newWidths: number[]) => {
    setColumnWidths(newWidths);
    setTableKey(nanoid());
  };

  const autoSizeColumn = (columnIndex: number | null) => {
    const newWidths = [...columnWidths];
    if (columnIndex === null) {
      for (let i = 0; i < columnsCount; i++) {
        newWidths[i] = tableRef.current?.locator?.getWidestVisibleCellInColumn(i) || 150;
      }
    } else {
      newWidths[columnIndex] = tableRef.current?.locator?.getWidestVisibleCellInColumn(columnIndex) || 150;
    }
    updateColumnsWidth(newWidths);
  };

  const shrinkColumnsToFitContainer = () => {
    const containerWidth = tableWrapperRef.current?.offsetWidth || 0;
    const equalWidth = containerWidth / columnsCount;
    const newWidths = new Array(columnsCount).fill(equalWidth);
    updateColumnsWidth(newWidths);
  };

  useEffect(() => {
    shrinkColumnsToFitContainer();
  }, []);

  return (
    <div ref={tableWrapperRef} style={{ height: '410px', overflow: 'auto' }}>
      {sortedData && sortedData[0] && columnWidths.length === DISPLAY_COLUMNS.length && (
        <Table2
          key={tableKey}
          ref={tableRef}
          numRows={sortedData.length}
          columnWidths={columnWidths}
          enableRowHeader={false}
        >
          {DISPLAY_COLUMNS.map((column, index) => (
            <Column
              key={index}
              columnHeaderCellRenderer={() => renderColumnHeader(column.display, column.key)}
              cellRenderer={(rowIndex: number) => {
                const cellData = sortedData[rowIndex][column.key as keyof CtaRevenueDataInterface];
                let formattedData = formatCellValue(column.key, cellData);
                if (column.key === 'baseUrl') formattedData = (<a href={formattedData} target="_blank">{formattedData}</a>)
                return <Cell>{formattedData !== null ? formattedData : 'N/A'}</Cell>;
              }}
            />
          ))}
        </Table2>
      )}
    </div>
  );
};

export default CtaTable;
