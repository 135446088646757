import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

function TextHookFormInput(props: {
  type?: 'text' | 'email' | 'password' | 'number' | 'textarea';
  error: FieldError | boolean;
  placeholder: string;
  register: UseFormRegisterReturn<string>;
}) {
  return (
    <input
      type={props.type || 'text'}
      placeholder={props.placeholder || ''}
      className={`block w-full rounded-sm border p-3  hover:outline-0 focus:outline-0 ${
        props.error ? 'border-red-500' : ''
      }`}
      {...props.register}
    />
  );
}

export default TextHookFormInput;
