import React from 'react';

export function FilledButton({
  content,
  additionalClasses,
  onClick,
}: {
  content: React.JSX.Element;
  additionalClasses?: string;
  onClick?: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className={`inline-flex h-10
      items-center justify-center
      gap-4 rounded-sm bg-zinc-800
      px-4 py-2 ${additionalClasses}`}
    >
      {content}
    </button>
  );
}
