// store.ts
import { StatsDataInterface, StatsGroupedByDate } from '@/types/stats';
import axios from 'axios';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IEspAccount } from '@/types/esp-account';
import { useAuthStore } from '@/store/auth/auth-store';
import { apiV1Charts, apiV1Ecpm } from '@/api';

type StatsState = {
  loading: boolean;
  statsData: StatsDataInterface[];
  chartData: StatsDataInterface[];
  overviewData: StatsDataInterface[];
  error: string | null;
  startDate: string | null;
  endDate: string | null;
  excludeList: string[];
};

type StatsActions = {
  setStartDate: (date: string | null) => void;
  setEndDate: (date: string | null) => void;
  setExcludeList: (val: string[]) => void;
  fetchStatsForAccount: (account: IEspAccount) => void;
  fetchStatsData: () => void;
  fetchOverviewStatsData: (account?: IEspAccount) => void;
};

export const useStatsStore = create<
  StatsState & StatsActions,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer<StatsState & StatsActions>((set, getState) => ({
      startDate: null,
      endDate: null,
      excludeList: [],
      setStartDate: (date) =>
        set((state) => {
          state.startDate = date;
        }),
      setEndDate: (date) =>
        set((state) => {
          state.endDate = date;
        }),
      setExcludeList: (val) =>
        set((state) => {
          state.excludeList = val;
        }),
      loading: false,
      statsData: [],
      chartData: [],
      overviewData: [],
      error: null,
      fetchStatsForAccount: async (account: IEspAccount) => {
        try {
          set((state) => {
            state.loading = true;
          });
          const { accessToken } = useAuthStore.getState();
          const response = await apiV1Charts.statsByAccount({
            params: {
              name: account.name,
              query: {
                start_date: getState().startDate,
                end_date: getState().endDate,
              },
            },
            headers: { Authorization: 'Bearer ' + accessToken },
          });
          const chartData = response.map((item: StatsDataInterface) => ({
            ...item,
            SR: parseFloat(((item.unsubs / item.sent) * 100).toFixed(4)),
          }));
          set((state) => {
            state.loading = false;
            state.chartData = chartData;
            state.error = null;
          });
        } catch (err: unknown) {
          console.log('err', err);
          set((state) => {
            state.loading = false;
            state.statsData = [];
            if (err instanceof Error) {
              state.error = `Failed to fetch chart data: ${err.message}`;
            } else {
              state.error = `Failed to fetch chart data`;
            }
          });
        }
      },
      fetchStatsData: async () => {
        try {
          set((state) => {
            state.loading = true;
          });
          const { accessToken } = useAuthStore.getState();
          const queryParams = {
            start_date: getState().startDate,
            end_date: getState().endDate,
          };
          const response = await apiV1Charts.stats({
            query: queryParams,
            headers: { Authorization: 'Bearer ' + accessToken },
          });
          console.log('RESP', response);
          const chartData = response.map((item: StatsDataInterface) => ({
            ...item,
            SR: parseFloat(((item.unsubs / item.sent) * 100).toFixed(4)),
          }));
          set((state) => {
            state.loading = false;
            state.chartData = chartData;
            state.error = null;
          });
        } catch (err: unknown) {
          console.log('err', err);
          set((state) => {
            state.chartData = [];
            state.loading = false;
            if (err instanceof Error) {
              state.error = `Failed to fetch chart data: ${err.message}`;
            } else {
              state.error = `Failed to fetch chart data`;
            }
          });
        }
      },
      fetchOverviewStatsData: async (account?: IEspAccount) => {
        try {
          set((state) => {
            state.loading = true;
          });
          const queryParams: {
            start_date: string | null;
            end_date: string | null;
            account_id?: string | number | null;
          } = {
            start_date: getState().startDate,
            end_date: getState().endDate,
          };
          if (account && account.id) {
            queryParams.account_id = account.id;
          }
          const { accessToken } = useAuthStore.getState();
          const response = await apiV1Charts.statsOverview({
            query: queryParams,
            headers: { Authorization: 'Bearer ' + accessToken },
          });
          const statsData = response.map((item: StatsDataInterface) => ({...item,}));
          set((state) => {
            state.loading = false;
            state.statsData = statsData;
            state.error = null;
          });
        } catch (err: unknown) {
          console.log('err', err);
          set((state) => {
            state.statsData = [];
            state.loading = false;
            if (err instanceof Error) {
              state.error = `Failed to fetch chart data: ${err.message}`;
            } else {
              state.error = `Failed to fetch chart data`;
            }
          });
        }
      },
    })),
  ),
);
