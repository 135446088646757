import React from 'react';
import { FilledButton } from '@/components/shared/buttons/FilledButton';
import { Link } from 'react-router-dom';

export function ApprovalStatusPage() {
  return (
    <div className="">
      <div className="container mx-auto grid grid-cols-5">
        <div className="col-span-1 min-h-[100vh]"></div>
        <div className="col-span-3 mt-[5px] bg-white px-[72px] pt-[120px]">
          <h1
            className="font-['Avenir'] text-[28px] font-extrabold leading-[30px] text-zinc-800
                         lg:leading-[44px]"
          >
            Your account is under review
          </h1>

          <Link to="/">
            <FilledButton
              content={
                <span className="text-[14px] font-medium text-white">
                  Check Status
                </span>
              }
            />
          </Link>
        </div>
        <div className="col-span-1 min-h-[100vh]"></div>
      </div>
    </div>
  );
}
