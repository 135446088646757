import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IEspAccount } from '@/types/esp-account';
import { apiV1Accounts } from '@/api';
import { useAuthStore } from '@/store/auth/auth-store';

type EspAccountsState = {
  espAccounts: IEspAccount[];
  error: string | null;
  currentEspAccount: IEspAccount | null;
};
type EspAccountsActions = {
  fetchEspAccounts: () => void;
  setCurrentEspAccount: (espProvider: IEspAccount | null) => void;
};

export const useEspAccountsStore = create<
  EspAccountsState & EspAccountsActions,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer<EspAccountsState & EspAccountsActions>((set) => ({
      espAccounts: [],
      error: null,
      currentEspAccount: null,
      setCurrentEspAccount: (espAccount: IEspAccount | null) => {
        set((state) => {
          state.currentEspAccount = espAccount;
        });
      },
      fetchEspAccounts: async () => {
        try {
          const { accessToken } = useAuthStore.getState();
          const response = await apiV1Accounts.list({headers: { Authorization: 'Bearer ' + accessToken },});
          set((state) => {
            state.espAccounts = response.sort();
            state.error = null;
          });
        } catch (err: unknown) {
          set((state) => {
            state.espAccounts = [];
            if (err instanceof Error) {
              state.error = `Failed to fetch esp providers: ${err?.message}`;
            } else {
              state.error = `Failed to fetch esp providers`;
            }
          });
        }
      },
    })),
  ),
);
