import { convertDateFormat } from '@/utils/ConvertDateFormat';
import React from 'react';
import { StatsGroupedByDate } from '@/types/stats';

export const DatesRow = ({chartDataByDate,}: {
  chartDataByDate: StatsGroupedByDate;
}) => {
  return (
    <ul
      className="days"
      style={{
        'grid-gap': 'var(--square-gap)',
        'grid-template-columns': `repeat(${
          Object.entries(chartDataByDate).length
        }, var(--square-size))`,
      }}
    >
      {Object.entries(chartDataByDate).map(([date, _accounts]) => {
        return <li key={date}>{convertDateFormat(date)}</li>;
      })}
    </ul>
  );
};
